import React from "react";
import styled from "styled-components";

const StyledInfoSubHeader = styled.p`
    font-size: 28px;
    margin-top: 50px;
`;

const InfoSubHeader = ({ children }) => {
    return <StyledInfoSubHeader>{children}</StyledInfoSubHeader>;
};

export default InfoSubHeader;
