import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useGlobalDispatchContext } from "../context/globalContext";

import CountDown from "./CountDown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faTwitter,
    faInstagram,
    faTwitterSquare,
} from "@fortawesome/free-brands-svg-icons";

import { motion } from "framer-motion";

const StyledSiteDisabled = styled.div`
    user-select: none;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    padding-top: 20px;
    font-size: 15px;
    height: 100vh;
    width: 100%;
    background: #ebebeb;
    overflow: hidden;
    p {
        width: 85%;
        max-width: 500px;
        margin: 8px 0;
    }
    .paimon-img {
        width: 20%;
    }
`;

const Socials = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    a {
        padding: 0 10px 14px 10px;
        cursor: pointer;
        color: ${(props) => props.theme.text};
    }
`;

const SiteDisabled = () => {
    const globalDispatch = useGlobalDispatchContext();

    useEffect(() => {
        let timer = setTimeout(() => {
            globalDispatch({ type: "STOP_APPLOADING" });
        }, 1600);

        globalDispatch({ type: "STOP_LOADING" });

        return () => {
            clearTimeout(timer);
        };
    }, []);

    return (
        <StyledSiteDisabled>
            {/* <p>
                hello everyone,
                <br />
                due to funds and fees, I need to close the website temporarily.
                thanks everyone for your support. I will try to fix those
                problems as soon as possible and reopen the website back once
                things have settled. there was just too many people at once :(
            </p>
            <p>
                大家好。因为各种费用和资金问题，我需要暂时关网站。谢谢大家的支持。我会尽快解决问题然后再把网站开回来。太多的人在同一时间在用。
                :(
            </p>
           <p>
                สวัสดีทุกคน.
                ฉันจำเป็นต้องปิดเว็บไซต์ชั่วคราวเนื่องจากปัญหาด้านค่าใช้จ่ายและเงินทุนต่างๆ
                ขอบคุณสำหรับการสนับสนุน.
                ฉันจะแก้ปัญหาโดยเร็วที่สุดแล้วเปิดเว็บไซต์อีกครั้ง
                มีคนใช้งานพร้อมกันมากเกินไป :(
            </p> 
            <p>
                みなさん、こんにちは。様々な費用や資金の問題により、一時的にウェブサイトを閉鎖する必要があります。ご協力ありがとうございました。早急に問題を解決し、再度ウェブサイトを開きます。同時に使う人が多すぎます。:(
            </p> */}
            <CountDown />
            <p>
                Hello everyone, due to funds and fees from large number of
                visitors around the world, I will need to close the site
                temporarily. Thanks everyone for your support. Site will return
                with new features and improvements.
            </p>
            <p>
                大家好，
                由于资金和支出，我需要暂时关闭该网站。感谢您的支持。该站点将返回新功能和改进。
            </p>
            <p>
                สวัสดีทุกคนเนื่องจากเงินทุนและค่าธรรมเนียมจากผู้เยี่ยมชมจำนวนมากทั่วโลกฉันจะต้องปิดเว็บไซต์ชั่วคราว
                ขอบคุณทุกคนสำหรับการสนับสนุนของคุณ
                ไซต์จะกลับมาพร้อมกับคุณสมบัติใหม่และการปรับปรุง
            </p>
            <p>
                みなさん、こんにちは。世界中の多くの訪問者からの資金と料金のために、私は一時的にサイトを閉鎖する必要があります。皆様のご支援に感謝いたします。サイトは新機能と改善で戻ってきます。
            </p>
            <p>
                For business inquiries:
                <br />
                miwoju.business@gmail.com
            </p>
            <p>Socials</p>
            <Socials>
                <motion.a
                    whileHover={{ scale: 1.2, color: "#1DA1F2", y: -6 }}
                    href="https://twitter.com/miwoju7"
                    target="_blank"
                    rel="noreferrer noopener"
                    transition={{ duration: 0.6 }}
                >
                    <FontAwesomeIcon className="fa-2x" icon={faTwitterSquare} />
                </motion.a>
                <motion.a
                    whileHover={{ scale: 1.2, color: "#C13584", y: -6 }}
                    href="https://www.instagram.com/miwoju/"
                    target="_blank"
                    rel="noreferrer noopener"
                    transition={{ duration: 0.6 }}
                >
                    <FontAwesomeIcon className="fa-2x" icon={faInstagram} />
                </motion.a>
            </Socials>
            <form
                action="https://www.paypal.com/donate"
                method="post"
                target="_blank"
            >
                <input
                    type="hidden"
                    name="hosted_button_id"
                    value="GESW5QUR7PCDJ"
                />
                <input
                    type="image"
                    src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif"
                    border="0"
                    name="submit"
                    title="PayPal - The safer, easier way to pay online!"
                    alt="Donate with PayPal button"
                />
                <img
                    alt=""
                    border="0"
                    src="https://www.paypal.com/en_US/i/scr/pixel.gif"
                    width="1"
                    height="1"
                />
            </form>
            <img
                className="paimon-img"
                src="https://i.ytimg.com/vi/O7vW6O5wJGU/maxresdefault.jpg"
            />
        </StyledSiteDisabled>
    );
};

export default SiteDisabled;
