import React, { useCallback, useEffect } from "react";
import styled, { css } from "styled-components";

import { useGlobalDispatchContext } from "../../context/globalContext";

import modalClose from "../../assets/audio/modal-close.mp3";
import modalConfirmAudio from "../../assets/audio/modal-button-confirm.mp3";

import { device } from "../../util/device";

import { useTranslation } from "react-i18next";

const StyledWishModal = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    z-index: 999;
    display: grid;
    place-items: center;
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0.2)
    );
`;

const Modal = styled.div`
    background-color: ${(props) => props.backgroundColor};
    height: ${(props) => props.height};
    width: ${(props) => props.width};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    border: 2px solid #dacbbf;
    color: #495365;

    padding: 8px 30px;

    @media ${device.tablet} {
        height: 70%;
        width: 80%;
        padding: 6px 14px;
    }
    @media ${device.mobileL} {
        height: 80%;
        width: 90%;
        padding: 0px 4px;
    }
`;

const ModalTitle = styled.h3`
    width: 100%;
    font-weight: 500;
    font-size: 28px;
    letter-spacing: -1px;

    padding: 10px 0;
    @media ${device.tablet} {
        font-size: 20px;
    }
    @media ${device.mobileL} {
        font-size: 16px;
    }
    ${(props) =>
        props.hr &&
        css`
            border-bottom: 3px solid #dbd3c5;
        `};
`;

const ModalContent = styled.div`
    display: flex;
    align-items: center;
    ${(props) =>
        props.justifyContent &&
        css`
            justify-content: center;
        `};
    flex-direction: column;
    font-size: ${(props) => props.contentFontSize};
    padding: ${(props) => props.contentPadding};
    line-height: ${(props) => props.contentLineHeight};
    /* line-height: 30px; */
    letter-spacing: -1.2px;
    word-spacing: 2.4px;
    /* padding: 0 15px; */
    width: 100%;
    height: 100%;
    @media ${device.tablet} {
        line-height: 20px;
        font-size: 20px;
    }
    @media ${device.mobileL} {
        line-height: 20px;
        font-size: 16px;
    }
`;

const ModalAction = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    ${(props) =>
        props.hr &&
        css`
            border-top: 3px solid #dbd3c5;
        `};

    @media ${device.tablet} {
        padding: 6px 0;
        flex-direction: column;
    }
    @media ${device.mobileL} {
        padding: 6px 0;
        flex-direction: column;
    }
`;

const ModalActionButton = styled.button`
    position: relative;
    display: flex;
    justify-content: center;
    font-size: 20px;
    letter-spacing: -1px;
    border: none;
    outline: none;
    color: ${(props) => props.backgroundColor};
    /* color: #ece5d7; */
    background-color: #4a5365;
    border-radius: 100px;
    height: 46px;
    width: 265px;
    margin: 10px 20px;
    padding: 10px;
    padding-left: 30px;
    .icon {
        position: absolute;
        display: grid;
        place-items: center;
        margin: 10px;
        left: 0px;
        align-self: center;
        background-color: #313131;
        border-radius: 100px;
        width: 30px;
        height: 30px;
        .icon-o {
            border: 2px solid #e2b632;
            border-radius: 100px;
            width: 15px;
            height: 15px;
        }
        .icon-x {
            position: relative;
            transform-origin: center;
            display: grid;
            place-items: center;
            div {
                position: absolute;
                background-color: #37a1e3;
                width: 18px;
                height: 3px;
                transform: rotate(45deg);
                border-radius: 10px;
            }
            div:nth-child(2) {
                transform: rotate(-45deg);
            }
        }
    }
    &:hover {
        box-shadow: 0 0 0 3px #f49c00;
    }
    @media ${device.tablet} {
        transform: scale(0.8);
        margin: 0;
    }
    @media ${device.mobileL} {
        transform: scale(0.5);
        margin: 0;
    }
`;

const WishModal = ({
    playAudio,
    children,
    title,
    onConfirm,
    confirmOnly,
    backgroundColor = "#E4E2DC",
    height = "422px",
    width = "680px",
    justifyContent = "center",
    hr = true,
    contentFontSize = "24.5px",
    contentLineHeight = "30px",
    contentPadding = "0 15px",
    customConfirmAudio,
}) => {
    const dispatch = useGlobalDispatchContext();
    const { t } = useTranslation();
    const closeModal = () => {
        dispatch({ type: "SET_WISHALERT", payload: false });
    };

    const handleKeyPress = useCallback(
        ({ key }) => {
            if (key === "Enter") {
                closeModal();
                onConfirm();
                if (!!customConfirmAudio) {
                    playAudio(customConfirmAudio);
                } else {
                    playAudio(modalConfirmAudio);
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [onConfirm]
    );

    useEffect(() => {
        window.addEventListener("keydown", handleKeyPress);
        return () => {
            window.removeEventListener("keydown", handleKeyPress);
        };
    }, [handleKeyPress]);

    const handleOutsideClick = (e) => {
        e.preventDefault();
        playAudio(modalClose);
        closeModal();
    };

    const handleAction = (input, e) => {
        closeModal();
        e.preventDefault();
        if (input) {
            onConfirm();
            if (!!customConfirmAudio) {
                playAudio(customConfirmAudio);
            } else {
                playAudio(modalConfirmAudio);
            }
        } else {
            playAudio(modalClose);
        }
    };

    return (
        <StyledWishModal onClick={(e) => handleOutsideClick(e)}>
            <Modal
                height={height}
                width={width}
                backgroundColor={backgroundColor}
                onClick={(e) => e.stopPropagation()}
            >
                <ModalTitle hr={hr}>{title}</ModalTitle>
                <ModalContent
                    contentFontSize={contentFontSize}
                    justifyContent={justifyContent}
                    contentLineHeight={contentLineHeight}
                    contentPadding={contentPadding}
                >
                    {children}
                </ModalContent>
                <ModalAction hr={hr}>
                    {!confirmOnly && (
                        <ModalActionButton
                            backgroundColor={backgroundColor}
                            onClick={(e) => handleAction(false, e)}
                        >
                            <div className="icon">
                                <div className="icon-x">
                                    <div />
                                    <div />
                                </div>
                            </div>
                            {t("Cancel")}
                        </ModalActionButton>
                    )}
                    <ModalActionButton
                        backgroundColor={backgroundColor}
                        onClick={(e) => handleAction(true, e)}
                    >
                        <div className="icon">
                            <div className="icon-o" />
                        </div>
                        {t("Confirm")}
                    </ModalActionButton>
                </ModalAction>
            </Modal>
        </StyledWishModal>
    );
};

export default WishModal;
