import React, { useState, useEffect } from "react";
//Styled components
import styled from "styled-components";
import CloseButton from "../CloseButton";

import { motion } from "framer-motion";

import PrimogemIcon from "../../assets/images/ui/primogem-icon.png";
import FateIcon from "../FateIcon";

import bannerClickSound from "../../assets/audio/banner-click.mp3";
import addButtonClick from "../../assets/audio/add-button.mp3";

import { useDataStateContext } from "../../context/dataContext";
import {
    useGlobalStateContext,
    useGlobalDispatchContext,
} from "../../context/globalContext";

import { useDataDispatchContext } from "../../context/dataContext";

import { device } from "../../util/device";

import WishModal from "./WishModal";
import VolumeButtons from "../VolumeButtons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faMinusCircle,
    faPlusCircle,
    faStar,
} from "@fortawesome/free-solid-svg-icons";

import "@fontsource/marko-one";

import { useTranslation } from "react-i18next";

import {
    pastBanners,
    futureBanners,
    newBanners,
} from "../../assets/data/banners.json";
import PastWishHeader from "./PastWishHeader";

import intoThePastAudio from "../../assets/audio/into-the-past.mp3";
import unlockStar from "../../assets/audio/unlock-stars2.mp3";

const StyledWishHeader = styled.div`
    font-weight: lighter;
    .secret-banner-img {
        width: 30px;
    }
    @media ${device.maxSize} {
        .banner-img {
            width: 150px;
        }
        .banner-title {
            font-size: 22px;
            .fa-star {
                font-size: 32px;
                margin-right: 34px;
            }
        }
        .currency {
            font-size: 24px;
            padding: 4px 4px 4px 10px;
            .currency-img {
                margin-right: 14px;
                width: 24px;
            }
        }
        .add-button {
            margin-left: 8px;
            width: 28px;
            height: 28px;
            div {
                background-color: #000;
                width: 3px;
                height: 16px;
            }
        }
    }
    @media ${device.desktopL} {
        .banner-img {
            width: 150px;
        }
        .banner-title {
            font-size: 22px;
            .fa-star {
                font-size: 32px;
                margin-right: 34px;
            }
        }
        .currency {
            font-size: 24px;
            padding: 4px 4px 4px 10px;
            .currency-img {
                margin-right: 14px;
                width: 24px;
            }
        }
        .add-button {
            margin-left: 8px;
            width: 28px;
            height: 28px;
            div {
                background-color: #000;
                width: 3px;
                height: 16px;
            }
        }
    }
    @media ${device.desktop} {
        .banner-img {
            width: 125px;
        }
        .banner-title {
            font-size: 20px;
            .fa-star {
                font-size: 30px;
                margin-right: 28px;
            }
        }
        .currency {
            font-size: 19px;
            padding: 3px 3px 3px 9px;
            .currency-img {
                margin-right: 10px;
                width: 22px;
            }
        }
        .add-button {
            margin-left: 6px;
            width: 24px;
            height: 24px;
            div {
                background-color: #000;
                width: 3px;
                height: 12px;
            }
        }
    }
    @media ${device.laptopL} {
        .banner-img {
            width: 115px;
            margin: 0 8px;
        }
        .banner-title {
            font-size: 18px;
            .fa-star {
                font-size: 28px;
                margin-right: 22px;
            }
        }
        .currency {
            font-size: 17px;
            padding: 2px 3px 2px 9px;
            .currency-img {
                margin-right: 8px;
                width: 20px;
            }
        }
        .add-button {
            margin-left: 4px;
            width: 22px;
            height: 22px;
            div {
                background-color: #000;
                width: 3px;
                height: 16px;
            }
        }
    }
    @media ${device.laptop} {
        .banner-img {
            margin: 0 5px;
            width: 90px;
        }
    }
    @media ${device.tablet} {
        .banner-img {
            margin: 0 5px;
            /* width: 90px; */
            width: 60px;
        }
        .secret-banner-img {
            width: 20px;
        }
    }
    @media ${device.mobileL} {
        .banner-img {
            margin: 0 2px;
            /* width: 75px; */
            width: 55px;
        }
        .currency {
            font-size: 12px;
            padding: 1px 3px 1px 7px;
            .currency-img {
                margin-right: 8px;
                width: 12px;
            }
        }
        .add-button {
            margin-left: 4px;
            width: 16px;
            height: 16px;
            div {
                background-color: #000;
                width: 2px;
                height: 13px;
            }
        }
        .secret-banner-img {
            width: 15px;
        }
    }
`;

const WishHeaderContainer = styled(motion.div)`
    display: grid;
    align-items: center;
    grid-template-columns: 1fr auto 1fr;
    width: 100%;
    @media ${device.tablet} {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        flex-wrap: wrap;
    }
`;

const BannerSelector = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 14px;
    .travel-button-text {
        color: #fff;
        position: absolute;
        top: 100%;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.6);
        white-space: nowrap;
        @media ${device.tablet} {
            font-size: 6px;
        }
        @media ${device.mobileL} {
            font-size: 5px;
        }
    }
`;

const BannerIMG = styled.img`
    margin: 0 12px;
    &:hover {
        transform: scale(1.1);
    }
`;

const BannerTitle = styled.h3`
    color: ${(props) => props.theme.background};
    font-family: "Marko One";
    letter-spacing: 0.5px;
    text-shadow: 1px 1px 4px rgba(156, 97, 97, 0.2);
    display: flex;
    align-items: center;
    font-size: 20px;
`;

const StarButton = styled.div`
    position: relative;
    .fa-star {
        color: #d7c398;
        font-size: 32px;
        margin-right: 34px;
        border-radius: 50px;
        &:hover {
            color: rgb(255, 234, 167);
            text-shadow: 0px 0px 12px rgba(255, 234, 167, 0.9);
        }
    }
    .activated {
        color: #fcffb0;
        text-shadow: 0px 0px 12px rgba(252, 255, 176, 0.9);
    }

    p {
        position: absolute;
        top: 100%;
        font-size: 12px;
        white-space: nowrap;
        margin-top: 4px;
        margin-left: -10px;
        color: #fcffb0;
    }
`;

const CurrencyContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    justify-self: flex-end;
    margin-right: 54px;
    @media ${device.tablet} {
        margin-right: 0;
    }
    @media ${device.mobileL} {
        flex-wrap: wrap;
    }
`;

const Currency = styled.div`
    min-height: 30px;
    display: flex;
    align-items: center;
    border-width: 0px;
    border-radius: 50px;
    color: #fefefe;
    background-color: rgba(55, 65, 85, 0.5);
    border: 2px solid rgba(235, 255, 255, 0.3);
    letter-spacing: 1.5px;
    margin-right: 22px;
    .currency-value {
        margin-right: 10px;
    }
    @media ${device.laptop} {
        transform: scale(0.9);
        margin-right: 6px;
    }
    @media ${device.tablet} {
        transform: scale(0.85);
        margin: 10px 1px;
    }
    @media ${device.mobileL} {
        transform: scale(0.8);
        margin: 10px 0px;
    }
`;

const AddButton = styled.button`
    position: relative;
    background-color: ${(props) => props.theme.backgroundColor};
    ${(props) => props.theme.shadow};

    border-width: 0;
    border-radius: 100%;
    display: grid;
    place-items: center;
    .line2 {
        position: absolute;
        /* transform-origin: center; */
        transform: rotate(90deg);
    }

    margin-left: 10px;
    width: 24px;
    height: 24px;
    div {
        background-color: #000;
        width: 3px;
        height: 12px;
    }
`;

const ModalSlider = styled.div`
    /* margin-top: 106px; */
    padding: 18px;
    width: 100%;
    display: grid;
    align-items: center;
    grid-template-columns: 0.3fr 1fr 350px 1fr 0.3fr;
    @media ${device.tablet} {
        grid-template-columns: 0.3fr 1fr 4fr 1fr 0.3fr;
        width: 80%;
        padding: 18px 0;
    }
    @media ${device.tablet} {
        grid-template-columns: 0.3fr 1fr 4fr 1fr 0.3fr;
        width: 90%;
        padding: 18px 0;
    }
    p {
        font-size: 20px;
    }
    .fas {
        color: #5b6984;
        font-size: 30px;
    }
    .slider {
        -webkit-appearance: none;
        height: 12px;
        background: #f1eee7;
        border: 2px solid #d6d1c5;
        border-radius: 10px;
        outline: none;
        opacity: 0.7;
        margin: 0 10px;
        -webkit-transition: 0.2s;
        transition: opacity 0.2s;
    }

    .slider:hover {
        opacity: 1;
    }
    .slider::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 16px;
        height: 16px;
        border-radius: 1px;
        border: 2px solid #e4e2dc;
        box-shadow: 0 0 0 3px #5b6984;
        background: #5b6984;
        cursor: pointer;
        transform: rotate(45deg);
    }
    .slider::-moz-range-thumb {
        width: 16px;
        height: 16px;
        border-radius: 1px;
        border: 2px solid #e4e2dc;
        box-shadow: 0 0 0 3px #5b6984;
        background: #5b6984;
        cursor: pointer;
        transform: rotate(45deg);
    }
`;

const WishHeader = ({ playAudio }) => {
    const { t } = useTranslation();

    const { primogemsAmount, fatesAmount } = useDataStateContext();
    const [modalValue, setModalValue] = useState(1);

    const {
        selectedBanner,
        bannerNumber,
        currentBanners,
        wishAlert,
        isPastBanner,
        isUnlimited,
    } = useGlobalStateContext();

    const globalDispatch = useGlobalDispatchContext();
    const dataDispatch = useDataDispatchContext();

    const gachaComposition = (gachaData) => {
        let numberOf5StarsInBanner = 0;
        let numberOf4StarsInBanner = 0;
        let gachaDataLength = gachaData.length;

        for (let i = 0; i < gachaDataLength; i++) {
            if (gachaData[i].rating === 5) {
                numberOf5StarsInBanner++;
            } else if (gachaData[i].rating === 4) {
                numberOf4StarsInBanner++;
            } else if (gachaData[i].rating === 3) {
                return {
                    numberOf5StarsInBanner,
                    numberOf4StarsInBanner,
                    gachaDataLength,
                };
            }
        }
    };

    useEffect(() => {
        globalDispatch({
            type: "SET_GACHADATA",
            payload: gachaComposition(
                require(`../../assets/data/${currentBanners[bannerNumber].data}`)
            ),
        });
    }, [bannerNumber, currentBanners, globalDispatch, selectedBanner]);

    const bannerClick = (index) => {
        globalDispatch({ type: "SET_BANNER", payload: index });
        playAudio(bannerClickSound);
    };

    const addClick = (e) => {
        e.stopPropagation();
        playAudio(addButtonClick);
        globalDispatch({ type: "SET_DISPLAY", payload: "shop" });
    };

    const convertClick = (e) => {
        e.stopPropagation();
        playAudio(addButtonClick);
        if (primogemsAmount >= 320) {
            globalDispatch({
                type: "SET_WISHALERT",
                payload: "convert-primogems",
            });
        }
    };

    const handleChange = (e) => {
        setModalValue(parseInt(e.target.value));
    };

    const handleConfirm = () => {
        dataDispatch({
            type: "SET_PRIMOGEMS",
            payload: primogemsAmount - modalValue * 160,
        });
        dataDispatch({
            type: "SET_FATES",
            payload: {
                ...fatesAmount,
                [currentBanners[selectedBanner].wishType]:
                    fatesAmount[currentBanners[selectedBanner].wishType] +
                    modalValue,
            },
        });
    };

    const handlePastBannerClick = () => {
        if (!isPastBanner) {
            playAudio(intoThePastAudio);
        }
        globalDispatch({
            type: "SET_CURRENTBANNERS",
            payload: !isPastBanner ? pastBanners : newBanners,
        });
        globalDispatch({
            type: "SET_BANNER",
            payload: 0,
        });
        globalDispatch({
            type: "SET_PASTBANNER",
            payload: !isPastBanner,
        });
    };

    const handleFutureBannerClick = () => {
        globalDispatch({
            type: "SET_CURRENTBANNERS",
            payload: !isPastBanner ? futureBanners : newBanners,
        });
        globalDispatch({
            type: "SET_BANNER",
            payload: 0,
        });
        globalDispatch({
            type: "SET_PASTBANNER",
            payload: !isPastBanner,
        });
    };

    const handleStarButtonClick = () => {
        globalDispatch({
            type: "SET_UNLIMITED",
            payload: !isUnlimited,
        });
        if (!isUnlimited) {
            playAudio(unlockStar);
        }
    };
    return (
        <StyledWishHeader>
            <WishHeaderContainer
                initial={{ y: -75, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5, ease: "circOut" }}
            >
                <BannerTitle className="banner-title">
                    <StarButton onClick={() => handleStarButtonClick()}>
                        <i
                            className={`fas fa-star ${
                                isUnlimited && "activated"
                            }`}
                        />
                        <p>
                            {!isUnlimited
                                ? "Click Me"
                                : "Unlocked Endless Fates!"}
                        </p>
                    </StarButton>
                    {isUnlimited && "Infinite "}
                    {t("Wish")}
                    <VolumeButtons />
                </BannerTitle>
                <BannerSelector>
                    {!isPastBanner ? (
                        currentBanners.map((banner, i) => (
                            <BannerIMG
                                className="banner-img"
                                draggable="false"
                                key={banner.bannerId}
                                src={require(`../../assets/images/banners/${
                                    banner.bannerId
                                }-${
                                    currentBanners[selectedBanner].bannerId ===
                                    banner.bannerId
                                        ? "selected"
                                        : "button"
                                }.png`)}
                                onClick={() => bannerClick(i)}
                            />
                        ))
                    ) : (
                        <PastWishHeader
                            currentBanners={currentBanners}
                            selectedBanner={selectedBanner}
                            bannerClick={bannerClick}
                        />
                    )}
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            position: "relative",
                            marginTop: "6px",
                        }}
                    >
                        <BannerIMG
                            className="banner-img"
                            draggable="false"
                            src={require(`../../assets/images/banners/${
                                isPastBanner
                                    ? newBanners[newBanners.length - 1].bannerId
                                    : pastBanners[0].bannerId
                            }-button.png`)}
                            onClick={() => handlePastBannerClick()}
                        />
                        <p className="travel-button-text">
                            {isPastBanner
                                ? "Return to future"
                                : "Time travel to past"}
                        </p>
                    </div>
                    {/* {!isPastBanner && (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                position: "relative",
                                // marginTop: "6px",
                            }}
                        >
                            <BannerIMG
                                style={{
                                    marginTop: "10px",
                                    marginLeft: "5px",
                                }}
                                className="secret-banner-img"
                                draggable="false"
                                src={require(`../../assets/images/banners/hu-tao-button.png`)}
                                onClick={() => handleFutureBannerClick()}
                            />
                        </div>
                    )} */}
                </BannerSelector>
                <CurrencyContainer>
                    <Currency
                        className="currency"
                        // onClick={() => currencyClick("primogem-details")}
                    >
                        <img
                            className="currency-img"
                            draggable="false"
                            src={PrimogemIcon}
                            alt="currency"
                        />
                        <p className="currency-value">
                            {!isUnlimited ? primogemsAmount : "∞"}
                        </p>
                        <AddButton
                            className="add-button"
                            onClick={(e) => addClick(e)}
                        >
                            <div className="line1" />
                            <div className="line2" />
                        </AddButton>
                    </Currency>
                    <Currency
                        className="currency"
                        // onClick={() => currencyClick("fate-details")}
                    >
                        <FateIcon
                            className="currency-img"
                            wishType={currentBanners[selectedBanner].wishType}
                        />
                        <p className="currency-value">
                            {!isUnlimited
                                ? fatesAmount[
                                      currentBanners[selectedBanner].wishType
                                  ]
                                : "∞"}
                        </p>
                        {primogemsAmount >= 320 && (
                            <AddButton
                                className="add-button"
                                onClick={(e) => convertClick(e)}
                            >
                                <div className="line1" />
                                <div className="line2" />
                            </AddButton>
                        )}
                    </Currency>
                </CurrencyContainer>
            </WishHeaderContainer>
            {wishAlert === "convert-primogems" && (
                <WishModal
                    playAudio={playAudio}
                    title={`Convert to ${
                        currentBanners[selectedBanner].wishType
                            .charAt(0)
                            .toUpperCase() +
                        currentBanners[selectedBanner].wishType.slice(1)
                    } Fate`}
                    onConfirm={() => handleConfirm()}
                >
                    <p style={{ fontSize: "18px" }}>Qty.</p>
                    <p style={{ fontSize: "30px", marginTop: "2px" }}>
                        {modalValue}
                    </p>
                    <ModalSlider>
                        <FontAwesomeIcon
                            icon={faMinusCircle}
                            className="fas fa-minus-circle"
                            onClick={() =>
                                setModalValue(
                                    modalValue - 1 > 1 ? modalValue - 1 : 1
                                )
                            }
                        />
                        <p>1</p>
                        <input
                            className="slider"
                            type="range"
                            min="1"
                            max={Math.floor(primogemsAmount / 160)}
                            onChange={(e) => handleChange(e)}
                            value={modalValue}
                        ></input>
                        <p>{Math.floor(primogemsAmount / 160)}</p>
                        <FontAwesomeIcon
                            icon={faPlusCircle}
                            className="fas fa-plus-circle"
                            onClick={() =>
                                setModalValue(
                                    modalValue <
                                        Math.floor(primogemsAmount / 160)
                                        ? modalValue + 1
                                        : Math.floor(primogemsAmount / 160)
                                )
                            }
                        />
                    </ModalSlider>
                </WishModal>
            )}
            <CloseButton onClick={() => handlePastBannerClick()} />
        </StyledWishHeader>
    );
};

export default WishHeader;
