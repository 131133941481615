// const size = {
//     // giant: "1170px",
//     giant: "1400px",
//     // desktopL: "992px",
//     desktopL: "1100px",
//     tablet: "768px",
//     phone: "500px",
// };

const size = {
    // mobileS: "320px",
    // mobileM: "375px",
    mobileL: "425px",
    tablet: "976px",
    laptop: "1140px",
    // laptopL: "1440px", originally
    laptopL: "1150px",
    desktop: "1900px",
    desktopL: "2560px",
    maxSize: "2560px",
};

// export default {
//     giant: `only screen and (max-width: ${size.giant})`,
//     desktopL: `only screen and (max-width: ${size.desktopL})`,
//     tablet: `only screen and (max-width: ${size.tablet})`,
//     phone: `only screen and (max-width: ${size.phone})`,
// };

export const device = {
    mobileS: `(max-width: ${size.mobileS})`,
    mobileM: `(max-width: ${size.mobileM})`,
    mobileL: `(max-width: ${size.mobileL})`,
    tablet: `(max-width: ${size.tablet})`,
    laptop: `(max-width: ${size.laptop})`,
    laptopL: `(max-width: ${size.laptopL})`,
    desktop: `(max-width: ${size.desktop})`,
    desktopL: `(max-width: ${size.desktopL})`,
    maxSize: `(min-width: ${size.maxSize})`,
};
