import React from "react";
import styled from "styled-components";

import { useGlobalStateContext } from "../context/globalContext";

import { motion, AnimatePresence } from "framer-motion";

import LoadingIcon from "../assets/images/element/Loading-Icons.png";

import { device } from "../util/device";

const StyledLoadingScreen = styled(motion.div)`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    background-color: #fff;
    z-index: 9999;

    display: grid;
    place-items: center;
    overflow: hidden;
    /* -webkit-animation: disappear 2s forwards;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-delay: 5s;
    animation: disappear 4s forwards;
    animation-iteration-count: 1;
    animation-delay: 2s; */
`;

const LoadingIcons = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 50px;
        margin: 6px;
    }
`;

const LoadingFilter = styled(motion.div)`
    position: absolute;
    /* background: linear-gradient(
        to right,
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 1) 400px,
        rgba(0, 0, 0, 0.4) 400px,
        rgba(0, 0, 0, 0.4)
    ); */

    background: linear-gradient(
        to right,
        #555,
        #555 420px,
        #e5e5e5 420px,
        #e5e5e5
    );
    width: 1260px;
    height: 100px;
    background-position-x: 0;
    animation: size 1.5s ease-out forwards;

    @keyframes size {
        /* 20% {
            background-position-x: 100px;
        } */
        40% {
            background-position-x: 105px;
        }
        60% {
            background-position-x: 157.5px;
        }
        70% {
            background-position-x: 357px;
        }
        100% {
            background-position-x: 420px;
        }
    }

    mask-repeat: no-repeat;
    mask-position: center;
    mask-image: url(${LoadingIcon});
    mask-size: 420px;

    @media ${device.tablet} {
        transform: scale(0.8);
    }
    @media ${device.mobileL} {
        transform: scale(0.55);
    }
`;

const LoadingScreen = () => {
    const { appLoading } = useGlobalStateContext();

    return (
        <>
            {appLoading && (
                <AnimatePresence>
                    <StyledLoadingScreen
                        initial={{ opacity: 1 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className={""}
                    >
                        <LoadingIcons
                            initial={{ backgroundPositionX: 0 }}
                            animate={{ backgroundPositionX: "500px" }}
                        ></LoadingIcons>
                        <LoadingFilter></LoadingFilter>
                    </StyledLoadingScreen>
                </AnimatePresence>
            )}
        </>
    );
};

export default LoadingScreen;
