import { nanoid } from "nanoid";

const fiveStarRates = 0.7;
const fourStarRates = 5.1;

export const weaponRoll = (
    bannerId,
    bannerType,
    data,
    featured4Star,
    featured5Star,
    pity4Star,
    pity5Star,
    guaranteedFeatured5Star,
    guaranteedFeatured4Star,
    gachaData,
    beginnerRolled,
    fateCost
) => {
    if (fateCost === 1) {
        return singleRoll(
            bannerId,
            bannerType,
            data,
            featured4Star,
            featured5Star,
            pity4Star,
            pity5Star,
            guaranteedFeatured5Star,
            guaranteedFeatured4Star,
            gachaData
        );
    } else {
        return tenRoll(
            bannerId,
            bannerType,
            data,
            featured4Star,
            featured5Star,
            pity4Star,
            pity5Star,
            guaranteedFeatured5Star,
            guaranteedFeatured4Star,
            gachaData,
            beginnerRolled
        );
    }
};

const coinFlip = () => {
    // console.log(!!Math.floor(Math.random() * 2));
    return !!Math.floor(Math.random() * 2);
};

const weaponFlip = () => {
    return !!(Math.random() * 100 <= 75);
};

const softPity = () => {
    return !!(Math.random() * 100 <= 10.4);
};

// const beginnerFirstRoll = (rolledAmount) => {
//     return rolledAmount === 0;
// };

const singleRoll = (
    bannerId,
    bannerType,
    data,
    featured4Star,
    featured5Star,
    pity4Star,
    pity5Star,
    guaranteedFeatured5Star,
    guaranteedFeatured4Star,
    gachaData
) => {
    const {
        numberOf5StarsInBanner,
        numberOf4StarsInBanner,
        gachaDataLength,
    } = gachaData;

    const gachaJSON = require(`../assets/data/${data}`);
    let rolledNumber;
    let randomNumber = Math.random() * 100;

    let currentGuaranteedFeatured5Star = guaranteedFeatured5Star;
    let currentGuaranteedFeatured4Star = guaranteedFeatured4Star;

    if (
        pity5Star >= 79 ||
        randomNumber <= fiveStarRates ||
        (pity5Star > 58 && softPity())
    ) {
        if ((guaranteedFeatured5Star || weaponFlip()) && !!featured5Star) {
            rolledNumber = Math.floor(Math.random() * featured5Star.length);

            currentGuaranteedFeatured5Star = false;
        } else {
            rolledNumber =
                Math.floor(Math.random() * (numberOf5StarsInBanner - 1)) + 1;

            currentGuaranteedFeatured5Star = true;
        }
    } else if (
        pity4Star >= 9 ||
        (randomNumber > fiveStarRates &&
            randomNumber < fiveStarRates + fourStarRates) ||
        //If both 5 star and 4 star is about to hit pity, give 4 star pity priority.
        (pity5Star >= 77 && pity4Star >= 7)
    ) {
        if ((guaranteedFeatured4Star || weaponFlip()) && !!featured4Star) {
            rolledNumber =
                Math.floor(Math.random() * featured4Star.length) +
                numberOf5StarsInBanner;

            currentGuaranteedFeatured4Star = false;
        } else {
            rolledNumber =
                Math.floor(Math.random() * numberOf4StarsInBanner) +
                numberOf5StarsInBanner;

            currentGuaranteedFeatured4Star = true;
        }
    } else {
        rolledNumber =
            Math.floor(
                Math.random() *
                    (gachaDataLength -
                        numberOf4StarsInBanner -
                        numberOf5StarsInBanner)
            ) +
            numberOf4StarsInBanner +
            numberOf5StarsInBanner;
    }

    let rolledItems = [];
    rolledItems.push({
        ...gachaJSON[rolledNumber],
        time: new Date().toISOString(),
        bannerType: bannerType,
        rollId: nanoid(),
    });

    const rollType =
        rolledItems[0].rating === 5
            ? "fiveStar"
            : rolledItems[0].rating === 4
            ? "fourStar"
            : "threeStar";

    console.log("YOU'VE WON", rolledItems[0].name, rollType);

    return {
        rolledItems,
        rollType,
        guaranteedFeatured4Star: currentGuaranteedFeatured4Star,
        guaranteedFeatured5Star: currentGuaranteedFeatured5Star,
    };
};

const tenRoll = (
    bannerId,
    bannerType,
    data,
    featured4Star,
    featured5Star,
    pity4Star,
    pity5Star,
    guaranteedFeatured5Star,
    guaranteedFeatured4Star,
    gachaData,
    beginnerRolled
) => {
    const {
        numberOf5StarsInBanner,
        numberOf4StarsInBanner,
        gachaDataLength,
    } = gachaData;

    // const gachaJSON = require(`./${data}`);
    const gachaJSON = require(`../assets/data/${data}`);
    let rolledItems = [];
    let rolledItemsRatings = [];
    let rolledNumber;
    let randomNumber;
    let maxRating = 3;

    let currentGuaranteedFeatured5Star = guaranteedFeatured5Star;
    let currentGuaranteedFeatured4Star = guaranteedFeatured4Star;

    let pityReset = false;

    for (let i = 0; i < 10; i++) {
        let isFeatured5Star = coinFlip();
        let isFeatured4Star = coinFlip();

        randomNumber = Math.random() * 100;
        // console.log("YOU", { randomNumber, pity4Star, pity5Star });

        if (
            (pity5Star + i > 78 && !pityReset) ||
            randomNumber <= fiveStarRates ||
            (pity5Star + i > 58 && softPity() && !pityReset)
        ) {
            rolledItemsRatings.push(5);
            maxRating = 5;
            pityReset = true;

            if (
                (currentGuaranteedFeatured5Star ||
                    isFeatured5Star ||
                    weaponFlip()) &&
                !!featured5Star
            ) {
                rolledNumber =
                    Math.floor(Math.random() * featured5Star.length - 1) + 1;

                currentGuaranteedFeatured5Star = false;
            } else {
                rolledNumber = Math.floor(
                    Math.random() * numberOf5StarsInBanner
                );

                currentGuaranteedFeatured5Star = true;
            }
        } else if (
            (pity4Star + i > 8 && !rolledItemsRatings.includes(4)) ||
            (randomNumber > fiveStarRates &&
                randomNumber < fiveStarRates + fourStarRates) ||
            //If both 5 star and 4 star is about to hit pity, give 4 star pity priority.
            (pity5Star + i > 77 &&
                pity4Star + i > 7 &&
                !rolledItemsRatings.includes(4))
        ) {
            rolledItemsRatings.push(4);
            //if this is noelle banner and is not first roll or guarantee is already gone due to first roll, exclude noelle for extra 4 stars.
            if (
                bannerType === "beginner" &&
                (i > 0 || !currentGuaranteedFeatured4Star)
            ) {
                //subtract noelle, hence subtract number of 4 stars
                rolledNumber =
                    Math.floor(
                        Math.random() * numberOf4StarsInBanner -
                            featured4Star.length -
                            1
                    ) +
                    numberOf5StarsInBanner +
                    featured4Star.length +
                    1;
            } else if (
                (currentGuaranteedFeatured4Star ||
                    isFeatured4Star ||
                    weaponFlip()) &&
                !!featured4Star
            ) {
                rolledNumber =
                    Math.floor(Math.random() * featured4Star.length) +
                    numberOf5StarsInBanner;

                currentGuaranteedFeatured4Star = false;
            } else {
                rolledNumber =
                    Math.floor(Math.random() * numberOf4StarsInBanner) +
                    numberOf5StarsInBanner;
                currentGuaranteedFeatured4Star = true;
            }
        } else {
            rolledItemsRatings.push(3);
            rolledNumber =
                Math.floor(
                    Math.random() *
                        (gachaDataLength -
                            numberOf4StarsInBanner -
                            numberOf5StarsInBanner)
                ) +
                numberOf4StarsInBanner +
                numberOf5StarsInBanner;
        }
        if (maxRating < 5) maxRating = 4;
        rolledItems.push({
            ...gachaJSON[rolledNumber],
            time: new Date().toISOString(),
            bannerType: bannerType,
            rollId: nanoid(),
        });
    }

    //!!Temporary fix to no 4 stars
    if (!rolledItemsRatings.includes(4)) {
        const index = rolledItemsRatings.indexOf(3);
        let newRolledNumber =
            Math.floor(Math.random() * numberOf4StarsInBanner) +
            numberOf5StarsInBanner;
        rolledItemsRatings.splice(index, 1, 4);
        rolledItems.splice(index, 1, {
            ...gachaJSON[newRolledNumber],
            time: new Date().toISOString(),
            bannerType: bannerType,
            rollId: nanoid(),
        });

        currentGuaranteedFeatured4Star = true;
    }

    const rollType = maxRating === 5 ? "fiveStarTen" : "fourStarTen";

    // console.log("YOU'VE WON", rolledItems);

    return {
        rolledItems,
        rollType,
        rolledItemsRatings,
        guaranteedFeatured4Star: currentGuaranteedFeatured4Star,
        guaranteedFeatured5Star: currentGuaranteedFeatured5Star,
        beginnerRolled: beginnerRolled + 1,
    };
};
