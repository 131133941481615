import React from "react";
import styled from "styled-components";

const StyledInfoContainer = styled.div`
        /* width: 1100px; */

    margin: 30px 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
        Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
`;

const InfoContainer = ({ children }) => {
    return <StyledInfoContainer>{children}</StyledInfoContainer>;
};

export default InfoContainer;
