import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ListView from "./ListView";

import InfoTitle from "../infoPageLayout/InfoTitle";

import { useDataStateContext } from "../../context/dataContext";

import { device } from "../../util/device";

import { useTranslation } from "react-i18next";

const StyledCollection = styled.div`
    .empty-collection {
        margin-top: 50px;
        font-size: 26px;
        text-align: center;
    }
    @media ${device.tablet} {
        transform: scale(0.9);
    }
    @media ${device.mobileL} {
        transform: scale(0.8);
    }
`;

const Collection = () => {
    const { t } = useTranslation();

    const { history } = useDataStateContext();
    const [collection, setCollection] = useState([]);
    // const [orderBy, setOrderBy] = useState("rating");
    // const [showOnly, setShowOnly] = useState("all");
    // const [view, setView] = useState("listView");

    useEffect(() => {
        // Organize the items to update quantity
        let collection;
        collection = Object.assign({}, collection);
        for (const item in collection) {
            collection[item] = Object.assign({}, collection[item]);
        }
        // Organize the items to update quantity
        for (let i = 0; i < history.length; i++) {
            if (collection[history[i].name]) {
                collection[history[i].name].quantity++;
            } else {
                collection[history[i].name] = history[i];
                collection[history[i].name].quantity = 1;
            }
        }

        setCollection(collection);

        return () => {};
    }, [history]);

    const sorting = {
        rating: (item1, item2) => item2.rating - item1.rating,
        quantity: (item1, item2) => item2.quantity - item1.quantity,
        name: (item1, item2) => item1.name.localeCompare(item2.name),
    };

    return (
        <StyledCollection>
            <InfoTitle>{t("Collection")}</InfoTitle>
            {history.length ? (
                Object.values(collection)
                    .sort(sorting.rating)
                    .map((item, i) => <ListView key={i} item={item}></ListView>)
            ) : (
                <div className="empty-collection">
                    Your collection is empty.
                    <br />
                    Make wishes to populate your collection.
                </div>
            )}
        </StyledCollection>
    );
};

export default Collection;
