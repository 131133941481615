import React from "react";
import styled from "styled-components";
import InfoStarsHeader from "./InfoStarsHeader";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const StyledInfoItemsTable = styled.div`
    color: #a3845b;
    h4 {
        font-weight: 600;
        font-size: 24px;
    }
`;

const GridContainer = styled.div`
    text-align: center;
    width: 100%;
    border-collapse: collapse;
`;

const GridItems = styled.div`
    display: grid;
    grid-template-columns: 240px 1fr 240px 1fr;
    border-top: 2px solid #d0cfcd;
    border-left: 2px solid #d0cfcd;
`;

const GridItem = styled.div`
    border-bottom: 2px solid #d0cfcd;
    border-right: 2px solid #d0cfcd;
    padding: 18px;
    font-size: 18px;
    color: #a7865a;
    text-transform: capitalize;
    &.grid-head {
        background-color: #dbd7d3;
        font-weight: 600;
        color: #4d4d4d;
    }
    .fas {
        font-size: 20px;
        margin: 0 5px 0 -18px;
        color: #98cb00;
    }
`;

const InfoItemsTable = ({ items }) => {
    let banner5Stars = [];
    let banner4Stars = [];
    let banner3Stars = [];

    for (let i = 0; i < items.length; i++) {
        const { type, name, isFeatured, rating } = items[i];
        if (rating === 5) {
            banner5Stars.push(<GridItem>{type}</GridItem>);
            banner5Stars.push(
                <GridItem>
                    {isFeatured && (
                        <FontAwesomeIcon
                            icon={faArrowUp}
                            className="fas fa-arrow-up"
                        />
                    )}
                    {name}
                </GridItem>
            );
        } else if (rating === 4) {
            banner4Stars.push(<GridItem>{type}</GridItem>);
            banner4Stars.push(
                <GridItem>
                    {isFeatured && (
                        <FontAwesomeIcon
                            icon={faArrowUp}
                            className="fas fa-arrow-up"
                        />
                    )}
                    {name}
                </GridItem>
            );
        } else if (rating === 3) {
            banner3Stars.push(<GridItem>{type}</GridItem>);
            banner3Stars.push(
                <GridItem>
                    {isFeatured && (
                        <FontAwesomeIcon
                            icon={faArrowUp}
                            className="fas fa-arrow-up"
                        />
                    )}
                    {name}
                </GridItem>
            );
        }
    }

    return (
        <StyledInfoItemsTable>
            <h4>Items to wish for:</h4>
            <InfoStarsHeader rating={5}>
                Base Probability for 5-Star Item Drops: 0.600% (Incl. guarantee:
                1.600%)
            </InfoStarsHeader>
            <GridContainer className="table">
                <GridItems>
                    <GridItem className="grid-head">Item Type</GridItem>
                    <GridItem className="grid-head">Item Name</GridItem>
                    <GridItem className="grid-head">Item Type</GridItem>
                    <GridItem className="grid-head">Item Name</GridItem>
                    {banner5Stars}
                </GridItems>
            </GridContainer>
            <InfoStarsHeader rating={4}>
                Base Probability for 4-Star Item Drops: 5.100% (Incl. guarantee:
                13.000%)
            </InfoStarsHeader>
            <GridContainer className="table">
                <GridItems>
                    <GridItem className="grid-head">Item Type</GridItem>
                    <GridItem className="grid-head">Item Name</GridItem>
                    <GridItem className="grid-head">Item Type</GridItem>
                    <GridItem className="grid-head">Item Name</GridItem>
                    {banner4Stars}
                </GridItems>
            </GridContainer>
            <InfoStarsHeader rating={3}>
                Base Probability for 3-Star Item Drops: 94.300% (Incl.
                guarantee: 85.400%)
            </InfoStarsHeader>
            <GridContainer className="table">
                <GridItems>
                    <GridItem className="grid-head">Item Type</GridItem>
                    <GridItem className="grid-head">Item Name</GridItem>
                    <GridItem className="grid-head">Item Type</GridItem>
                    <GridItem className="grid-head">Item Name</GridItem>
                    {banner3Stars}
                </GridItems>
            </GridContainer>
        </StyledInfoItemsTable>
    );
};

export default InfoItemsTable;
