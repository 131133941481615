import React, { useEffect, useCallback, useState } from "react";

// import React, { useState, useEffect } from "react";

//Styled components
import styled from "styled-components";
import WishVideo from "./WishVideo";

import ResultsModal from "./ResultsModal";
import InfoPage from "../infoPage/infoPage";

import {
    useGlobalStateContext,
    useGlobalDispatchContext,
} from "../../context/globalContext";

import { useDataStateContext } from "../../context/dataContext";

import ShopPage from "../shopPage/shopPage";
import DisclaimerModal from "../DisclaimerModal";
import WishContainer from "./WishContainer";

// import { currentBanners, pastBanners } from "../../assets/data/banners.json";
import ResultsSingleModal from "./ResultsSingleModal";

import reveal3Star from "../../assets/audio/3star-reveal.mp3";
import reveal4Star from "../../assets/audio/4star-reveal.mp3";
import reveal5Star from "../../assets/audio/5star-reveal.mp3";

// import bg from "../assets/images/ui/background.png";

import loginAudio from "../../assets/audio/login.mp3";
import backgroundMusic from "../../assets/audio/wish-background-music.mp3";
import backgroundMusicPast from "../../assets/audio/wish-background-music-past.mp3";

import { url } from "../../util/config.json";
import { device } from "../../util/device";

//!TEMP
import i18n from "../../i18n.js";

const StyledWishPage = styled.div`
    position: relative;
    ${(props) =>
        !props.isPastBanner
            ? `
        background: url(${url}/images/ui/background.png) no-repeat;
    `
            : `
        background: url(${url}/images/ui/wish-background.jpeg) no-repeat;
    `};
    background-size: cover;
    /* height: 100vh; */
    min-height: 100vh;
    width: 100%;
    margin: 0 auto;
    /**!Check to see if overflow is good */
    /* user-select: none; */

    /* @media only screen and (max-height: 600px) {
        min-height: 100vh;
        height: auto;
    } */
`;

const UID = styled.a`
    font-size: 13px;
    position: absolute;
    right: 0;
    bottom: 0;
    color: #fff;
    padding: 5px 50px;
    text-shadow: -1px -1px 2px rgba(0, 0, 0, 0.2);
    @media ${device.tablet} {
        padding: 5px 40px;
        font-size: 10px;
    }
    @media ${device.mobileL} {
        padding: 5px 25px;
        font-size: 6px;
    }

    &:hover {
        color: ${(props) => props.theme.text};
    }
    z-index: 2;
`;

const WishPage = () => {
    const [disclaimerOpen, setDisclaimerOpen] = useState(true);

    const {
        display,
        wishAlert,
        audio,
        appLoading,
        volume,
        bannerNumber,
        playBGM,
        currentBanners,
        isPastBanner,
        playClip,
    } = useGlobalStateContext();
    const globalDispatch = useGlobalDispatchContext();

    const {
        results: { rolledItems },
        bannerStats: {
            beginner: { beginnerRolled },
        },
    } = useDataStateContext();

    const playAudio = async (audioSrc) => {
        if (audioSrc) {
            await globalDispatch({
                type: "SET_AUDIO",
                payload: audioSrc,
            });
            const sound = document.getElementById("audio");
            sound.currentTime = 0;
            sound.play();
        } else if (audioSrc) {
            const sound = document.getElementById("audio");
            sound.pause();
            sound.currentTime = 0;
        }
    };

    const playWishRevealAudio = (audioId) => {
        const sound = document.getElementById(audioId);
        // document.getElementById("reveal-3star").currentTime = 0;
        // document.getElementById("reveal-4star").currentTime = 0;
        // document.getElementById("reveal-5star").currentTime = 0;
        sound.currentTime = 0;
        sound.play();
    };

    const backButton = useCallback(() => {
        globalDispatch({
            type: "SET_WISHALERT",
            payload: false,
        });
        //Set like this in case bug
        globalDispatch({
            type: "SET_DISPLAY",
            payload: false,
        });
    }, [globalDispatch]);

    useEffect(() => {
        let sound = document.getElementById("login");
        sound.volume = isNaN(volume.masterVolume) ? 0.5 : volume.masterVolume;
        sound.play();
    }, []);

    useEffect(() => {
        window.addEventListener("keydown", ({ key }) => {
            if (key === "Escape") backButton();
        });
        document.getElementById("reveal-3star").volume = isNaN(
            volume.masterVolume
        )
            ? 0.5
            : volume.masterVolume;
        document.getElementById("reveal-4star").volume = isNaN(
            volume.masterVolume
        )
            ? 0.5
            : volume.masterVolume;
        document.getElementById("reveal-5star").volume = isNaN(
            volume.masterVolume
        )
            ? 0.5
            : volume.masterVolume;
        document.getElementById("audio").volume = isNaN(volume.masterVolume)
            ? 0.5
            : volume.masterVolume;

        let timer = setTimeout(() => {
            globalDispatch({ type: "STOP_APPLOADING" });
        }, 1600);

        globalDispatch({ type: "STOP_LOADING" });

        globalDispatch({ type: "SET_DISPLAY", payload: false });

        let newCurrentBanners = currentBanners;

        if (beginnerRolled === 2 && newCurrentBanners.length === 4) {
            newCurrentBanners.shift();
            globalDispatch({
                type: "SET_BANNER",
                payload: 0,
            });
        }

        //Surprisingly simple fix to reset bug where when beginner banner restores,
        //the selectedBanner doesn't change from featured banner leading to all 4 star pulls
        if (bannerNumber === 0) {
            globalDispatch({
                type: "SET_BANNER",
                payload: 0,
            });
        }

        globalDispatch({
            type: "SET_CURRENTBANNERS",
            payload: newCurrentBanners,
        });

        // globalDispatch({ type: "SET_WISHALERT", payload: "disclaimer" });
        var pageVisible = true;
        const handleVisibilityChange = () => {
            const sound = document.getElementById("wish-background-music");

            if (document.hidden) {
                pageVisible = false;
                if (sound) {
                    sound.pause();
                }
            } else if (playBGM) {
                pageVisible = true;
                if (sound) {
                    sound.play();
                }
            }
            // console.log("handleVisibilityChange");
            // console.log("pageVisible", pageVisible);
            // some function call
        };
        document.addEventListener(
            "visibilitychange",
            handleVisibilityChange,
            false
        );
        window.addEventListener(
            "focus",
            function () {
                pageVisible = true;
                // some function call
            },
            false
        );
        window.addEventListener(
            "blur",
            function () {
                pageVisible = false;
                // some function call
            },
            false
        );

        return () => {
            clearTimeout(timer);
            window.removeEventListener("keydown", ({ key }) => {
                if (key === "Escape") backButton();
            });
            document.removeEventListener(
                "visibilitychange",
                handleVisibilityChange
            );
        };
    }, [
        backButton,
        globalDispatch,
        volume,
        beginnerRolled,
        bannerNumber,
        playBGM,
    ]);

    // useEffect(() => {
    //     let soundBGM = document.getElementById("wish-background-music");
    //     soundBGM.volume =
    //         volume.backgroundMusic - 0.2 < 0 || isNaN(volume.masterVolume - 0.2)
    //             ? 0
    //             : volume.masterVolume - 0.2;

    //     if (!playBGM || playClip || display) {
    //         soundBGM.pause();
    //     } else {
    //         soundBGM.play();
    //     }
    // }, [playClip, display, playBGM, volume]);

    return (
        <StyledWishPage isPastBanner={isPastBanner}>
            {display === "results-single" && rolledItems && (
                <ResultsSingleModal playWishRevealAudio={playWishRevealAudio} />
            )}
            {/* <ResultsSingleModal playWishRevealAudio={playWishRevealAudio} /> */}
            <audio preload="auto" id="reveal-5star" src={reveal5Star} />
            <audio preload="auto" id="reveal-4star" src={reveal4Star} />
            <audio id="reveal-3star" src={reveal3Star} />
            <audio id="login" src={loginAudio} />
            {/* <audio
                id="wish-background-music"
                src={!isPastBanner ? backgroundMusic : backgroundMusicPast}
                autoPlay
                loop
            /> */}

            <audio id="audio" src={audio} />
            {display === false && !appLoading && (
                <WishContainer playAudio={playAudio}></WishContainer>
            )}
            <WishVideo />
            {display === "shop" && (
                <ShopPage playAudio={playAudio} backButton={backButton} />
            )}
            {display === "results-full" && rolledItems && (
                <ResultsModal playAudio={playAudio} backButton={backButton} />
            )}
            {/* <ResultsModal playAudio={playAudio} backButton={backButton} /> */}

            {disclaimerOpen && (
                <DisclaimerModal
                    title="Miwoju's Disclaimer"
                    contentFontSize="24px"
                    contentLineHeight="30px"
                    contentPadding="0px"
                    height="70%"
                    width="50%"
                    confirmOnly={true}
                    setDisclaimerOpen={setDisclaimerOpen}
                    onConfirm={() => setDisclaimerOpen(false)}
                    playAudio={playAudio}
                ></DisclaimerModal>
            )}
            {display === "collection" && (
                <InfoPage backButton={backButton} pageId="collection" />
            )}
            {display === "details" && (
                <InfoPage backButton={backButton} pageId="details" />
            )}
            {display === "history" && (
                <InfoPage backButton={backButton} pageId="history" />
            )}
            <UID
                // href="https://thekima.com"
                target="_blank"
            >
                UID: MIWOJU'S SIMULATOR
            </UID>
        </StyledWishPage>
    );
};

export default WishPage;
