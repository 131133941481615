import styled, { css } from "styled-components";
import { device } from "../util/device";

export const Container = styled.div`
    flex-grow: 1;
    margin: 0 auto;
    padding: 0 42px;
    position: relative;
    width: auto;
    height: 100%;
    @media ${device.mobileL} {
        margin: 0;
        padding: 0;
    }
    /* @media (min-width: 1024px) {
        max-width: 960px;
    }
    @media (min-width: 1216px) {
        max-width: 1152px;
    }
    @media (min-width: 1408px) {
        max-width: 1244px;
    } */
    ${(props) =>
        props.fluid &&
        css`
            padding: 0;
            margin: 0;
            background: red;
            max-width: 100% !important;
        `}
    ${(props) =>
        props.vh &&
        css`
            height: 100vh;
        `}
`;

export const Flex = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    ${(props) =>
        props.column &&
        css`
            flex-direction: column;
        `};
    ${(props) =>
        props.center &&
        css`
            justify-content: center;
        `};
    ${(props) =>
        props.spaceBetween &&
        css`
            justify-content: space-between;
        `};
    ${(props) =>
        props.flexEnd &&
        css`
            justify-content: flex-end;
        `};
    ${(props) =>
        props.alignTop &&
        css`
            align-items: flex-start;
        `};
    ${(props) =>
        props.fullHeight &&
        css`
            height: 100%;
        `};
    ${(props) =>
        props.noHeight &&
        css`
            height: 0;
        `};
`;

// export const Cursor = styled.img`
//     position: absolute;
//     top: -50%;
//     left: -50%;
//     width: 26px;
//     border-radius: 100%;
//     transform: translate(-50%, -50%);
//     transition: all 0.1s ease-in-out;
//     transition-property: width, height, border;
//     will-change: width, height, transform, border;
//     pointer-events: none;
//     z-index: 999;
// `;
