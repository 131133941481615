import React from "react";
import styled from "styled-components";

const StyledInfoWishFeatured = styled.div`
    span {
        &.highlight {
            color: #ec4923;
        }
        &.highlight-purple {
            color: #a469d5;
        }
        &.highlight-brown {
            color: #be815a;
        }
    }
    .subheader {
        color: #555555;
    }
`;

const Duration = styled.div`
    background-color: #a37f51;
    color: #fff;
    padding: 8px 14px;
    margin: 25px 0;
    font-size: 24px;
    color: #fff;
`;

const WishDetails = styled.div`
    font-size: 22px;
    color: #727272;
    line-height: 40px;
    font-weight: 500;
`;

const InfoCharacters = styled.span`
    display: inline;
    span {
        text-transform: capitalize;
        &.pyro {
            color: #e06042;
        }
        &.hydro {
            color: #2cbcec;
        }
        &.geo {
            color: #dcc181;
        }
        &.cryo {
            color: #26c0f5;
        }
    }
`;

const Rules = styled.div``;

const Duplicate = styled.div``;

const InfoWishFeatured = ({
    duration,
    featured4Star,
    featured5Star,
    bannerTitle,
    bannerType,
}) => {
    const featuredCharactersText = (featuredCharacters) =>
        featuredCharacters.map(({ item, type, title }, i) =>
            featuredCharacters.length === 1 ? (
                <InfoCharacters key={i}>
                    <span className={type}>
                        {`"${title}" ${item}`} ({type})
                    </span>
                </InfoCharacters>
            ) : i < featuredCharacters.length - 1 ? (
                <InfoCharacters key={i}>
                    <span className={type}>
                        {`"${title}" ${item}`} ({type})
                    </span>
                    ,{" "}
                </InfoCharacters>
            ) : (
                <InfoCharacters key={i}>
                    and{" "}
                    <span className={type}>
                        {`"${title}" ${item}`} ({type})
                    </span>
                </InfoCharacters>
            )
        );
    return (
        <StyledInfoWishFeatured>
            <Duration>{duration}</Duration>
            <WishDetails>
                <p>
                    {bannerTitle} is now available. During this event wish, the{" "}
                    <span className="orange">event-exclusive</span> 5-star
                    character {featuredCharactersText(featured5Star)} as well as
                    4-star characters {featuredCharactersText(featured4Star)}{" "}
                    will get a{" "}
                    <span className="highlight">huge drop-rate boost</span>!
                    <br />
                    <span className="highlight">
                        ※ Of the above characters, the event-exclusive character
                        will not be available in the standard wish "Wanderlust
                        Invocation".
                    </span>
                </p>
                <br />
                <Rules>
                    <p className="subheader">〓Rules〓</p>
                    <p>5-Star Items</p>
                    <p>
                        For {bannerTitle}: Base probability of winning 5-star
                        character = <span className="highlight">0.600%</span>;
                        consolidated probability (incl. guarantee) ={" "}
                        <span className="highlight">1.600%</span>; guaranteed to
                        win 5-star character at least once per{" "}
                        <span className="highlight">90</span> attempts. The
                        first time you win a 5-star item in this event wish,
                        there is a <span className="highlight">50.000%</span>{" "}
                        chance it will be the promotional character{" "}
                        {featuredCharactersText(featured5Star)}. If the first
                        5-star character you win in this event wish is not the
                        promotional character, then the next 5-star character
                        you win is <span className="highlight">guaranteed</span>{" "}
                        to be the promotional character.
                    </p>
                    <p>4-Star Items</p>
                    <p>
                        For {bannerTitle}: Base probability of winning 4-star
                        item = <span className="highlight">5.100%</span>; base
                        probability of winning 4-star character ={" "}
                        <span className="highlight">2.550%</span>, and base
                        probability of winning 4-star weapon ={" "}
                        <span className="highlight">2.550%</span>; consolidated
                        probability (incl. guarantee) of winning 4-star item ={" "}
                        <span className="highlight">13.000%</span>; guaranteed
                        to win 4-star or above item at least once per{" "}
                        <span className="highlight">10</span> attempts;
                        probability of winning 4-star item through the guarantee
                        = <span className="highlight">99.400%</span>, and
                        probability of winning 5-star item through the guarantee
                        = <span className="highlight">0.600%</span>. <br />
                        The first time you win a 4-star item in this event wish,
                        there is a <span className="highlight">
                            50.000%
                        </span>{" "}
                        chance it will be one of the featured characters{" "}
                        {featuredCharactersText(featured4Star)}. If the first
                        4-star item you win in this event wish is not one of the
                        featured characters, then the next 4-star item you win
                        is <span className="highlight">guaranteed</span> to be a
                        featured character.
                    </p>
                    <br />
                    <p>
                        4-star weapons won in this wish come with{" "}
                        <span className="highlight-brown">
                            Masterless Starglitter
                        </span>{" "}
                        ×2; 3-star weapons won in this wish come with{" "}
                        <span className="highlight-purple">
                            Masterless Stardust
                        </span>{" "}
                        ×15.
                    </p>
                    <br />
                </Rules>
                <Duplicate>
                    <p className="subheader">〓Duplicate Characters〓</p>
                    <p>
                        On obtaining a 5-star character that you already own
                        (whether obtained in a wish, redeemed at the shop, or
                        awarded by the game): The 2nd – 7th time you obtain the
                        character, it will be converted into{" "}
                        <span className="highlight-purple">
                            that character's Stella Fortuna
                        </span>{" "}
                        ×1 and{" "}
                        <span className="highlight-brown">
                            Masterless Starglitter
                        </span>{" "}
                        ×10; from the 8th time onwards it will be converted into{" "}
                        <span className="highlight-brown">
                            Masterless Starglitter
                        </span>{" "}
                        ×25.
                    </p>
                    <p>
                        On obtaining a 4-star character that you already own
                        (whether obtained in a wish, redeemed at the shop, or
                        awarded by the game): The 2nd – 7th time you obtain the
                        character, it will be converted into{" "}
                        <span className="highlight-purple">
                            that character's Stella Fortuna
                        </span>{" "}
                        ×1 and{" "}
                        <span className="highlight-brown">
                            Masterless Starglitter
                        </span>{" "}
                        ×2; from the 8th time onwards it will be converted into{" "}
                        <span className="highlight-brown">
                            Masterless Starglitter
                        </span>{" "}
                        ×5.
                    </p>
                    <br />
                    <p>
                        ※ This is a character event wish. The wish guarantee
                        count is accumulated within character event wishes only
                        and is independent of the guarantee counts of other
                        types of wishes.
                    </p>
                    <br />
                    <br />
                </Duplicate>
            </WishDetails>
        </StyledInfoWishFeatured>
    );
};

export default InfoWishFeatured;
