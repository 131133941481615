import React from "react";
import styled from "styled-components";

const StyledInfoWishWeapon = styled.div`
    span {
        &.highlight {
            color: #ec4923;
        }
        &.highlight-purple {
            color: #a469d5;
        }
        &.highlight-brown {
            color: #be815a;
        }
    }
    .subheader {
        color: #555555;
    }
`;

const Duration = styled.div`
    background-color: #a37f51;
    color: #fff;
    padding: 8px 14px;
    margin: 25px 0;
    font-size: 24px;
    color: #fff;
`;

const WishDetails = styled.div`
    font-size: 22px;
    color: #727272;
    line-height: 40px;
    font-weight: 500;
`;

const InfoCharacters = styled.span`
    display: inline;
    span {
        text-transform: capitalize;
        &.pyro {
            color: #e06042;
        }
        &.hydro {
            color: #2cbcec;
        }
        &.geo {
            color: #dcc181;
        }
        &.cryo {
            color: #26c0f5;
        }
    }
`;

const Rules = styled.div``;

const Duplicate = styled.div``;

const InfoWishWeapon = ({
    duration,
    featured4Star,
    featured5Star,
    bannerTitle,
    bannerType,
}) => {
    const featuredItemsText = (featuredItems, rarity) =>
        featuredItems.map(({ item, type }, i) =>
            featuredItems.length === 1 ? (
                <InfoCharacters key={i}>
                    <span
                        className={
                            rarity === 5
                                ? "highlight-brown"
                                : "highlight-purple"
                        }
                    >
                        {`${item}`} ({type})
                    </span>
                </InfoCharacters>
            ) : i < featuredItems.length - 1 ? (
                <InfoCharacters key={i}>
                    <span
                        className={
                            rarity === 5
                                ? "highlight-brown"
                                : "highlight-purple"
                        }
                    >
                        {`${item}`} ({type})
                    </span>
                    ,{" "}
                </InfoCharacters>
            ) : (
                <InfoCharacters key={i}>
                    and{" "}
                    <span
                        className={
                            rarity === 5
                                ? "highlight-brown"
                                : "highlight-purple"
                        }
                    >
                        {`${item}`} ({type})
                    </span>
                </InfoCharacters>
            )
        );
    return (
        <StyledInfoWishWeapon>
            <Duration>{duration}</Duration>
            <WishDetails>
                <p>
                    {bannerTitle} is now available. During this event wish, the{" "}
                    <span className="orange">event-exclusive</span> 5-star
                    weapons {featuredItemsText(featured5Star, 5)} as well as
                    4-star weapons {featuredItemsText(featured4Star)} will get a{" "}
                    <span className="highlight">huge drop-rate boost</span>!
                    {/* <span className="highlight">
                        ※ Of the above weapons, the event-exclusive weapon will
                        not be available in the standard wish "Wanderlust
                        Invocation".
                    </span> */}
                </p>
                <br />
                <Rules>
                    <p className="subheader">〓Rules〓</p>
                    <p>5-Star Items</p>
                    <p>
                        For {bannerTitle}: Base probability of winning 5-star
                        weapon = <span className="highlight">0.700%</span>;
                        consolidated probability (incl. guarantee) ={" "}
                        <span className="highlight">1.850%</span>; guaranteed to
                        win 5-star weapon at least once per{" "}
                        <span className="highlight">80</span> attempts. <br />
                        The first time you win a 5-star weapon in this event,
                        there is a <span className="highlight">75%</span> chance
                        it will be one of the promotional weapons{" "}
                        {featuredItemsText(featured5Star, 5)}. If the first
                        5-star weapon you win in this event wish is not one of
                        the promotional weapons, then the next 5-star weapon you
                        win is <span className="highlight">guaranteed</span> to
                        be a promotional weapon.
                    </p>
                    <p>4-Star Items</p>
                    <p>
                        For {bannerTitle}: Base probability of winning 4-star
                        item = <span className="highlight">6.000%</span>; base
                        probability of winning 4-star character ={" "}
                        <span className="highlight">3.000%</span>, and base
                        probability of winning 4-star weapon ={" "}
                        <span className="highlight">3.000%</span>; consolidated
                        probability (incl. guarantee) of winning 4-star item ={" "}
                        <span className="highlight">14.500%</span>; guaranteed
                        to win 4-star or above item at least once per{" "}
                        <span className="highlight">10</span> attempts;
                        probability of winning 4-star item through the guarantee
                        = <span className="highlight">99.300%</span>, and
                        probability of winning 5-star item through the guarantee
                        = <span className="highlight">0.700%</span>.
                        <br />
                        The first time you win a 4-star item in this event wish,
                        there is a <span className="highlight">75%</span> chance
                        that it will be one of the featured weapons{" "}
                        {featuredItemsText(featured4Star)}. If the first 4-star
                        item you win in this event wish is not one of the
                        featured weapons, then the next 4-star item you win is{" "}
                        <span className="highlight">guaranteed</span> to be a
                        featured weapon.
                    </p>
                    <br />
                    <p>
                        5-star weapons won in this wish come with{" "}
                        <span className="highlight-brown">
                            Masterless Starglitter
                        </span>{" "}
                        ×10; 4-star weapons won in this wish come with{" "}
                        <span className="highlight-brown">
                            Masterless Starglitter
                        </span>{" "}
                        ×2; 3-star weapons won in this wish come with{" "}
                        <span className="highlight-purple">
                            Masterless Stardust
                        </span>{" "}
                        ×15.
                    </p>
                </Rules>
                <br />
                <Duplicate>
                    <p className="subheader">〓Duplicate Characters〓</p>
                    <p>
                        On obtaining a 4-star character that you already own
                        (whether obtained in a wish, redeemed at the shop, or
                        awarded by the game): The 2nd – 7th time you obtain the
                        character, it will be converted into{" "}
                        <span className="highlight-purple">
                            that character's Stella Fortuna
                        </span>{" "}
                        ×1 and{" "}
                        <span className="highlight-brown">
                            Masterless Starglitter
                        </span>{" "}
                        ×2; from the 8th time onwards it will be converted into{" "}
                        <span className="highlight-brown">
                            Masterless Starglitter
                        </span>{" "}
                        ×5.
                    </p>
                    <br />
                    <p>
                        ※ This is a weapon event wish. The wish guarantee count
                        is accumulated within this event only and is independent
                        of the guarantee counts of other wishes.
                    </p>
                    <br />
                    <br />
                </Duplicate>
            </WishDetails>
        </StyledInfoWishWeapon>
    );
};

export default InfoWishWeapon;
