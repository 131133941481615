import React from "react";
import styled from "styled-components";

const StyledInfoTitle = styled.div`
    background-color: #dbdbdb;
    height: 60px;
    display: flex;
    align-items: center;
    padding: 0 50px;
    /* margin: 30px 0 50px 0; */
    h2 {
        font-weight: 700;
        font-size: 38px;
        letter-spacing: 0.6px;
        color: #4e4e4e;
    }
    span {
        color: ${(props) => props.bannerColor};
    }
`;

const InfoTitle = ({ children, bannerColor }) => {
    return (
        <StyledInfoTitle bannerColor={bannerColor}>
            <h2>{children}</h2>
        </StyledInfoTitle>
    );
};

export default InfoTitle;
