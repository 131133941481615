import React from "react";
//Styled components
import styled from "styled-components";

import AcquaintFateIcon from "../assets/images/ui/acquaint-fate-icon.png";
import IntertwinedFateIcon from "../assets/images/ui/intertwined-fate-icon.png";

import { device } from "../util/device";
const StyledFateIcon = styled.img`
    width: 24px;
    /* padding: 2px; */
    margin-right: 12px;
    @media ${device.mobileL} {
        width: 14px;
    }
`;

const FateIcon = ({ wishType }) => {
    return (
        <StyledFateIcon
            className="fate-icon"
            draggable="false"
            src={
                wishType === "acquaint" ? AcquaintFateIcon : IntertwinedFateIcon
            }
        ></StyledFateIcon>
    );
};

export default FateIcon;
