import React from "react";

import i18n from "../i18n";

//Components
import LoadingScreen from "../components/LoadingScreen";
import WishPage from "../components/wishPage/wishPage";
import Layout from "../components/layout";

import { useGlobalStateContext } from "../context/globalContext";
import SEO from "../components/seo";
import SiteDisabled from "../components/SiteDisabled";

import { useStaticQuery, graphql } from "gatsby";


//!SPECIAL NOTES FOR APP
//!Update version through npm version
//!Banner renewal: The json are to be ordered by 5 stars first following 4 stars.
//!Banner renewal: Must add permanent banner file like wanderlust-xiao.json due to banner button usually being changed.

function IndexPage() {
    // const darkTheme = {};

    return (
        <Layout>
            <LoadingScreen />
            <WishPage />
            {/* <SiteDisabled /> */}
            <SEO title="Genshin Wish Simulator" />
        </Layout>
    );
}

export default IndexPage;

export const query = graphql`
    query($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
