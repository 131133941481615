import React, { useCallback, useEffect } from "react";
import styled, { css } from "styled-components";

import { useGlobalDispatchContext } from "../context/globalContext";

import disclaimerConfirmAudio from "../assets/audio/disclaimer-button-confirm.mp3";

import { device } from "../util/device";

import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faInstagram,
    faTiktok,
    faTwitter,
} from "@fortawesome/free-brands-svg-icons";

import { motion } from "framer-motion";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

const StyledDisclaimerModal = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    z-index: 999;
    display: grid;
    place-items: center;
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0.2)
    );
    @media ${device.maxSize} {
        .modal-content {
            font-size: 26px;
            line-height: 40px;
            p {
                margin: 16px 0;
            }
        }
    }
    @media ${device.desktopL} {
        .modal-content {
            font-size: 24px;
            line-height: 34px;
            p {
                margin: 12px 0;
            }
        }
    }
    @media ${device.desktop} {
        .modal-content {
            font-size: 22px;
            line-height: 25px;
            p {
                margin: 12px 0;
            }
        }
    }
    @media ${device.laptopL} {
        .modal {
            width: 80%;
        }
        .modal-content {
            font-size: 20px;
            p {
                margin: 12px 0;
            }
        }
    }
    @media ${device.laptop} {
        .modal {
        }
        .modal-content {
            font-size: 20px;
        }
    }
    @media ${device.tablet} {
        .modal {
        }
        .modal-content {
            font-size: 14px;
            line-height: 16px;
            p {
                margin: 4px;
            }
            .patch-notes {
                font-size: 12px;
            }
        }
    }
    @media ${device.mobileL} {
        .modal {
        }
        .modal-content {
            font-size: 12px;
            .patch-notes {
                font-size: 10px;
            }
        }
    }
`;

const Modal = styled.div`
    background-color: ${(props) => props.backgroundColor};
    /* height: ${(props) => props.height}; */
    width: ${(props) => props.width};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    border: 2px solid #dacbbf;
    color: #495365;

    padding: 8px 30px;
`;

const ModalTitle = styled.h3`
    width: 100%;
    font-weight: 500;
    font-size: 28px;
    letter-spacing: -1px;

    padding: 10px 0;
    ${(props) =>
        props.hr &&
        css`
            border-bottom: 3px solid #dbd3c5;
        `};
`;

const ModalContent = styled.div`
    display: flex;
    align-items: center;
    ${(props) =>
        props.justifyContent &&
        css`
            justify-content: center;
        `};
    flex-direction: column;
    font-size: ${(props) => props.contentFontSize};
    padding: ${(props) => props.contentPadding};
    line-height: ${(props) => props.contentLineHeight};
    /* line-height: 30px; */
    letter-spacing: -1.2px;
    word-spacing: 2.4px;
    /* padding: 0 15px; */
    width: 100%;
    height: 100%;
    .assets-disclaimer {
        font-size: 16px;
        @media ${device.tablet} {
            font-size: 12px;
        }
        @media ${device.mobileL} {
            font-size: 8px;
        }
    }
    .donation-container {
        display: flex;
        align-items: center;
        justify-content: center;
        /* width: 200px; */
        @media ${device.tablet} {
            width: 80%;
        }
        @media ${device.mobileL} {
            width: 100%;
        }
        .coffee-button {
            width: 130px;
            @media ${device.tablet} {
                width: 90px;
            }
            @media ${device.mobileL} {
                width: 60px;
            }
        }
    }
`;

const ModalAction = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    ${(props) =>
        props.hr &&
        css`
            border-top: 3px solid #dbd3c5;
        `};

    @media ${device.tablet} {
        padding: 0;
    }
    @media ${device.mobileL} {
        margin: 0px;
        padding: 0;
    }
`;

const ModalActionButton = styled.button`
    position: relative;
    display: flex;
    justify-content: center;
    font-size: 20px;
    letter-spacing: -1px;
    border: none;
    outline: none;
    color: ${(props) => props.backgroundColor};
    /* color: #ece5d7; */
    background-color: #4a5365;
    border-radius: 100px;
    height: 46px;
    width: 265px;
    margin: 10px 20px;
    padding: 10px;
    padding-left: 30px;
    .icon {
        position: absolute;
        display: grid;
        place-items: center;
        margin: 10px;
        left: 0px;
        align-self: center;
        background-color: #313131;
        border-radius: 100px;
        width: 30px;
        height: 30px;
        .icon-o {
            border: 2px solid #e2b632;
            border-radius: 100px;
            width: 15px;
            height: 15px;
        }
        .icon-x {
            position: relative;
            transform-origin: center;
            display: grid;
            place-items: center;
            div {
                position: absolute;
                background-color: #37a1e3;
                width: 18px;
                height: 3px;
                transform: rotate(45deg);
                border-radius: 10px;
            }
            div:nth-child(2) {
                transform: rotate(-45deg);
            }
        }
    }
    &:hover {
        box-shadow: 0 0 0 3px #f49c00;
    }
    @media ${device.tablet} {
        transform: scale(0.7);
    }
    @media ${device.mobileL} {
        margin: 0px;
        transform: scale(0.6);
    }
`;

const PatchNotes = styled.div`
    text-align: left;
    background-color: #fff;
    border: 1px dashed #777;
    padding: 5px;
    font-size: 14px;
    line-height: 1.5;
    margin-top: 20px;
    .patch-header {
        width: 100%;
        background-color: #fff;
    }
    .patch-content {
        padding-top: 5px;
        height: 150px;
        overflow-y: scroll;
        @media ${device.tablet} {
            height: 95px;
        }
        /* ::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 5px;
        }
        ::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background-color: rgba(37, 14, 14, 0.3);
            box-shadow: 0 0 1px rgba(255, 255, 255, 0.3);
            -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.3);
        } */
    }
`;

const DisclaimerModal = ({
    playAudio,
    children,
    title,
    onConfirm,
    confirmOnly,
    setDisclaimerOpen,
    backgroundColor = "#E4E2DC",
    height = "422px",
    width = "680px",
    justifyContent = "center",
    hr = true,
    contentFontSize = "24.5px",
    contentLineHeight = "30px",
    contentPadding = "0 15px",
}) => {
    const { t } = useTranslation();

    const dispatch = useGlobalDispatchContext();

    const closeModal = () => {
        playAudio(disclaimerConfirmAudio);
        setDisclaimerOpen(false);
    };

    const handleKeyPress = useCallback(
        ({ key }) => {
            if (key === "Enter") {
                closeModal();
                onConfirm();
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [onConfirm]
    );

    useEffect(() => {
        window.addEventListener("keydown", handleKeyPress);
        return () => {
            window.removeEventListener("keydown", handleKeyPress);
        };
    }, [handleKeyPress]);

    const handleOutsideClick = (e) => {
        e.preventDefault();
        closeModal();
    };

    const handleAction = (input, e) => {
        closeModal();
        e.preventDefault();
        if (input) {
            onConfirm();
        }
    };

    return (
        <StyledDisclaimerModal>
            <Modal
                className="modal"
                height={height}
                width={width}
                backgroundColor={backgroundColor}
            >
                <ModalTitle hr={hr}>{t("Disclaimer")}</ModalTitle>
                <ModalContent
                    className="modal-content"
                    contentFontSize={contentFontSize}
                    justifyContent={justifyContent}
                    contentLineHeight={contentLineHeight}
                    contentPadding={contentPadding}
                >
                    <p style={{ fontWeight: "300", letterSpacing: "-0.8px" }}>
                        {t("Hello")}
                        <span style={{ color: "#F49C00" }}>Miwoju/미워주</span>.
                        <br />
                        Nice to meet you.
                    </p>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <motion.a
                            href="https://www.instagram.com/miwoju"
                            rel="noreferrer noopener"
                            target="_blank"
                            style={{ color: "#000" }}
                            whileHover={{
                                scale: 1.5,
                                y: -10,
                                color: "#E1306C",
                            }}
                        >
                            <FontAwesomeIcon
                                style={{ margin: "3px 10px" }}
                                icon={faInstagram}
                            />
                        </motion.a>
                        <motion.a
                            href="https://twitter.com/miwoju7"
                            rel="noreferrer noopener"
                            target="_blank"
                            style={{ color: "#000" }}
                            whileHover={{
                                scale: 1.5,
                                y: -10,
                                color: "#1DA1F2",
                            }}
                        >
                            <FontAwesomeIcon
                                style={{ margin: "3px 10px" }}
                                icon={faTwitter}
                            />
                        </motion.a>
                        <motion.a
                            href="https://www.tiktok.com/@miwoju"
                            rel="noreferrer noopener"
                            target="_blank"
                            style={{ color: "#000" }}
                            whileHover={{
                                scale: 1.5,
                                y: -10,
                                color: "#EE1D52",
                            }}
                        >
                            <FontAwesomeIcon
                                style={{ margin: "3px 10px" }}
                                icon={faTiktok}
                            />
                        </motion.a>
                    </div>
                    <p>
                        For business inquiries:
                        <br />
                        <motion.a
                            href="mailto:miwoju.business@gmail.com"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: "#000" }}
                            whileHover={{
                                color: "#FF9966",
                            }}
                        >
                            <FontAwesomeIcon icon={faEnvelope} />{" "}
                            <span style={{ color: "#444" }}>
                                miwoju.business@gmail.com
                            </span>
                        </motion.a>
                    </p>
                    <PatchNotes className="patch-notes">
                        <div className="patch-header">
                            <span style={{ color: "#F49C00" }}>Notice: </span>
                            <span style={{ color: "#EE1D52" }}>
                                Jan 6, 2022
                            </span>
                            <hr />
                        </div>
                        {/* <div className="patch-content">
                            Simulator will be down for a day in order to
                            compensate for lack of funds. Please be patient
                            while services are transferred.
                        </div> */}
                        <div className="patch-content">
                            Shenhe banner has been released! As previously
                            mentioned, simulator is no longer public access and
                            is gated to supporters due to high traffic and
                            costs. Please understand, this project was
                            originally for fun.
                            <br />
                            <br />
                            Due to high number of requests for link, here it is:
                            <br />
                            <a
                                href="https://ty4coffee.thekima.com/"
                                style={{ color: "#F49C00" }}
                            >
                                https://ty4coffee.thekima.com/
                            </a>
                            <br />
                            Unfortunately, the passcode for access is provided
                            to supporters only.
                            <br />
                            Thanks for understanding.
                            <br />
                            {/* <span style={{ color: "#F49C00" }}>
                                Version 1.7.0{" "}
                            </span>
                            (9-09-2021): Added NEW! Raiden and Kujou Sara has
                            been added! Happy wishing!
                            <br />
                            <span style={{ color: "#F49C00" }}>
                                Version 1.6.1{" "}
                            </span>
                            (8-30-2021): Restored site for now. Going through
                            things in life at the moment, will get back to
                            updating whenever I can.
                            <br />
                            <span style={{ color: "#F49C00" }}>
                                Version 1.6.0{" "}
                            </span>
                            (7-26-2021): I am back! NEW Ayaka has been added,
                            and Kazuha has been retroactively added. Thank you
                            for your patience.
                            <br />
                            <span style={{ color: "#F49C00" }}>
                                Version 1.5.1{" "}
                            </span>
                            (5-27-2021): Bugfix on collections page, that
                            prevented it from loading after acquiring Eula.
                            <br />
                            <span style={{ color: "#F49C00" }}>
                                Version 1.5.0{" "}
                            </span>
                            (5-26-2021): Eula added, weapon banner added.
                            <br />
                            <span style={{ color: "#F49C00" }}>
                                Version 1.4.2{" "}
                            </span>
                            (4-12-2021): A bit late, but Rosaria released! And
                            Tartaglia makes a comeback! Weapon banner also
                            updated!
                            <br />
                            <span style={{ color: "#F49C00" }}>
                                Version 1.4.1{" "}
                            </span>
                            (3-30-2021): Unlimited Fates! Click the yellow Star
                            on the very top left to unlock Infinite Wishes!
                            <br />
                            <span style={{ color: "#F49C00" }}>
                                Version 1.4.0{" "}
                            </span>
                            (3-19-2021): Return of the Venti banner! Plus new
                            "Elegy for the End"!
                            <br />
                            <span style={{ color: "#F49C00" }}>
                                Version 1.3.1{" "}
                            </span>
                            (3-10-2021): UID watermark added to prevent people
                            from taking advantage and deceiving people with the
                            simulator.
                            <br />
                            <span style={{ color: "#F49C00" }}>
                                Version 1.3.0{" "}
                            </span>
                            (3-2-2021): Hu tao moved to official banner!
                            <br />
                            <span style={{ color: "#F49C00" }}>
                                Version 1.2.3{" "}
                            </span>
                            (2-28-2021): Splash art finally renewed! Roll to
                            figure out what this means :)
                            <br />
                            <span style={{ color: "#F49C00" }}>
                                Version 1.2.2{" "}
                            </span>
                            (2-27-2021): Shhhh, there's a secret! Hu Tao is
                            hidden somewhere. Can you find her?
                            <br />
                            <span style={{ color: "#F49C00" }}>
                                Version 1.2.1{" "}
                            </span>
                            (2-26-2021): New weapon banner for Staff of Homa
                            added!
                            <br />
                            <span style={{ color: "#F49C00" }}>
                                Version 1.2.0{" "}
                            </span>
                            (2-25-2021): You can now travel back to the past! Go
                            crazy and wish for Venti, Klee, Zhongli, & so on...
                            <br />
                            <span style={{ color: "#F49C00" }}>
                                Version 1.1.0{" "}
                            </span>
                            (2-18-2021): New Keqing banner is out! Xiao banner
                            is kept in for now. */}
                        </div>
                    </PatchNotes>
                    <p style={{ fontSize: "16px" }}>
                        This is only a Genshin Impact simulator. If you want to
                        play the actual game, please visit:
                        <br />
                        <a
                            href="https://genshin.mihoyo.com/en/download"
                            target="_blank"
                            rel="noreferrer"
                            // style={{ color: "#F49C00" }}
                            style={{ color: "#444" }}
                        >
                            https://genshin.mihoyo.com/
                        </a>
                    </p>
                    {/* <p>
                        *crosses finger there isn't another storm of visitors*
                    </p> */}
                    {/* <p style={{ fontWeight: "300", letterSpacing: "-0.8px" }}>
                        Hello,
                        <br /> Unfortunately, due to popularity{" "}
                        <span style={{ color: "#F49C00" }}>the fees</span> are
                        getting expensive. I did not originally create this to
                        make money, but I might have to shut it down until
                        everything settles down if I cannot get enough funds to
                        support it. For now, happy rolling!
                    </p> */}
                    {/* <p>
                        If you'd to support this app, please
                        support it through:
                    </p> */}
                    {/* <br /> */}
                    {/* <div className="donation-container">
                        <div style={{ fontSize: "18px" }}>
                            Paypal
                            <form
                                action="https://www.paypal.com/donate"
                                method="post"
                                target="_blank"
                            >
                                <input
                                    type="hidden"
                                    name="hosted_button_id"
                                    value="GESW5QUR7PCDJ"
                                />
                                <input
                                    type="image"
                                    src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif"
                                    border="0"
                                    name="submit"
                                    title="PayPal - The safer, easier way to pay online!"
                                    alt="Donate with PayPal button"
                                />
                                <img
                                    alt=""
                                    border="0"
                                    src="https://www.paypal.com/en_US/i/scr/pixel.gif"
                                    width="1"
                                    height="1"
                                />
                            </form>
                        </div>
                or */}
                    <div className="donation-container">
                        <a
                            href="https://www.buymeacoffee.com/miwoju"
                            rel="noreferrer"
                            target="_blank"
                        >
                            <img
                                className="coffee-button"
                                src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png"
                                alt="Buy Me A Coffee"
                                // style={{
                                //     width: "200px",
                                // }}
                            />
                        </a>
                    </div>
                    {/* </div> */}
                    <p>
                        <br />
                        <span
                            className="assets-disclaimer"
                            // style={{ fontSize: "16px" }}
                        >
                            All assets used belong to miHoYo Co., Ltd.
                        </span>
                    </p>
                </ModalContent>
                <ModalAction hr={hr}>
                    <ModalActionButton
                        backgroundColor={backgroundColor}
                        onClick={(e) => handleAction(true, e)}
                    >
                        <div className="icon">
                            <div className="icon-o" />
                        </div>
                        {t("Confirm")}
                    </ModalActionButton>
                </ModalAction>
            </Modal>
        </StyledDisclaimerModal>
    );
};

export default DisclaimerModal;
