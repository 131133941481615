import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";

import { device } from "../../util/device";

import { useTranslation } from "react-i18next";

// import ReactStars from "react-rating-stars-component";
const characterDetailsThumbs = require.context(
    "../../assets/images/character/thumbnails"
);

const GridContainer = styled.div`
    /* width: 100%; */
    /* width: 700px; */
    margin: 0 auto;
    @media ${device.maxSize} {
        width: 900px;
    }
    @media ${device.desktopL} {
        width: 900px;
    }
    @media ${device.desktop} {
        width: 900px;
    }
    @media ${device.laptopL} {
        width: 700px;
    }
    @media ${device.tablet} {
        width: 90%;
    }
    @media ${device.mobileL} {
        width: 100%;
    }
    /* background-color: #ffffff; */

    .highlight-purple {
        color: #b459ff;
        font-weight: 500;
    }
    .highlight-brown {
        color: #e97731;
        font-weight: 500;
    }
    .three-star {
        border: 2px solid #d0cfcd;
    }
    .four-star {
        border: 2px solid #b459ff;
    }
    .five-star {
        border: 2px solid #e97731;
    }
`;

const Grid = styled.div`
    display: grid;
    grid-template-columns: 170px 1fr 130px 130px;
    align-items: center;
    place-items: center;
    height: 90px;
    /* background-color: #fff; */
    margin: 18px 0;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    text-align: center;
    @media ${device.tablet} {
        /* width: 90%; */
    }
    @media ${device.mobileL} {
        grid-template-columns: 1fr 2fr 1fr 1fr;
    }
`;

const GridItem = styled.div`
    img {
        width: 64px;
        &.weapon {
            width: 50px;
        }
    }
    .fa-star {
        font-size: 16px;
        margin: 0 1px;
        color: #ffcd2e;
        margin-top: 6px;
    }
    p {
        font-weight: 400;
        color: #4e4e4e;
        font-size: 20px;
    }
`;

const weaponDetailsThumbs = require.context("../../assets/images/weapon");

export default function ListView(props) {
    const { t } = useTranslation();

    const { name, type, rating, src, quantity } = props.item;
    return (
        <GridContainer>
            <Grid
                className={
                    rating === 4
                        ? "four-star"
                        : rating === 5
                        ? "five-star"
                        : "three-star"
                }
            >
                <GridItem>
                    <img
                        className={type === "weapon" ? "weapon" : ""}
                        src={
                            type === "weapon"
                                ? weaponDetailsThumbs(`./${src}`)
                                : characterDetailsThumbs(
                                      `./${name.toLowerCase()}.png`
                                  )
                        }
                        alt="item"
                    />
                </GridItem>
                <GridItem>
                    <p
                        className={
                            rating === 4
                                ? "highlight-purple"
                                : rating === 5
                                ? "highlight-brown"
                                : ""
                        }
                    >
                        {t(name)}
                    </p>
                    {Array.apply(0, Array(rating)).map((star, i) => (
                        <FontAwesomeIcon
                            icon={faStar}
                            key={i}
                            className="fas fa-star"
                        />
                    ))}
                </GridItem>
                <GridItem
                    style={{
                        placeSelf: "start",
                        alignSelf: "center",
                        marginLeft: "80px",
                    }}
                >
                    {type === "weapon" ? (
                        <p>R{quantity < 5 ? quantity : 5}</p>
                    ) : (
                        <p>C{quantity < 7 ? quantity - 1 : 6}</p>
                    )}
                </GridItem>
                <GridItem>
                    <p>
                        x{" "}
                        <span
                            className={
                                rating === 4
                                    ? "highlight-purple"
                                    : rating === 5
                                    ? "highlight-brown"
                                    : ""
                            }
                        >
                            {quantity}
                        </span>
                    </p>
                </GridItem>
            </Grid>
        </GridContainer>
    );
}
