import React from "react";
import styled from "styled-components";
// import { Container, Row, Col } from "reactstrap";
import InfoWishFeatured from "../infoPageLayout/InfoWishFeatured";
import InfoItemBox from "../infoPageLayout/InfoItemBox";
import InfoStarsHeader from "../infoPageLayout/InfoStarsHeader";
import InfoSubHeader from "../infoPageLayout/InfoSubHeader";
// import DetailsBox from "./details-components/details-box";
// import StarsBar from "./details-components/stars-bar";
// import SubheadingSeparator from "./details-components/subheading-separator";
// import EventDurationHeading from "./details-components/event-duration-heading";
// import AdriftInTheHarbor from "../models/adrift-in-the-harbor";
// import Table from "./details-components/table";
import InfoTitle from "../infoPageLayout/InfoTitle";
import InfoItemsTable from "../infoPageLayout/InfoItemsTable";

import InfoWishWeapon from "../infoPageLayout/InfoWishWeapon";

import { useGlobalStateContext } from "../../context/globalContext";

import { device } from "../../util/device";

const StyledDetails = styled.div`
    margin: 0 auto;

    @media ${device.maxSize} {
        width: 1300px;
    }
    @media ${device.desktopL} {
        width: 1300px;
    }
    @media ${device.desktop} {
        width: 1200px;
    }
    @media ${device.laptopL} {
        width: 1100px;
    }
    @media ${device.tablet} {
        width: 90%;
    }
    @media ${device.mobileL} {
        width: 100%;
    }
`;

const Details = () => {
    const { selectedBanner, currentBanners } = useGlobalStateContext();

    const {
        bannerId,
        bannerColor,
        bannerTitle,
        featured5Star,
        featured4Star,
        bannerType,
    } = currentBanners[selectedBanner];
    return (
        <StyledDetails>
            <InfoTitle bannerColor={bannerColor}>{bannerTitle}</InfoTitle>
            {featured5Star && featured4Star && (
                <>
                    <InfoSubHeader>Increased Drop Rates!</InfoSubHeader>
                    <InfoStarsHeader rating={5}>
                        Percentage of 5-Star Item Drops：50.000%
                    </InfoStarsHeader>
                    <InfoItemBox
                        bannerType={bannerType}
                        items={featured5Star}
                    ></InfoItemBox>
                    <InfoStarsHeader rating={4}>
                        Percentage of 4-Star Item Drops：50.000%
                    </InfoStarsHeader>
                    <InfoItemBox
                        bannerType={bannerType}
                        items={featured4Star}
                    ></InfoItemBox>
                </>
            )}
            {bannerType === "weapon" && (
                <>
                    <InfoSubHeader>Wish Details</InfoSubHeader>
                    <InfoWishWeapon
                        duration="Limited Time Event"
                        featured4Star={featured4Star}
                        featured5Star={featured5Star}
                        bannerTitle={bannerTitle}
                    ></InfoWishWeapon>
                </>
            )}
            {bannerType === "featured" && (
                <>
                    <InfoSubHeader>Wish Details</InfoSubHeader>
                    <InfoWishFeatured
                        duration="Limited Time Event"
                        featured4Star={featured4Star}
                        featured5Star={featured5Star}
                        bannerTitle={bannerTitle}
                    ></InfoWishFeatured>
                </>
            )}
            <InfoItemsTable
                items={require(`../../assets/data/${bannerId}.json`)}
            ></InfoItemsTable>
        </StyledDetails>
    );
};

export default Details;
