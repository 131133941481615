import React from "react";
import styled from "styled-components";

import bg from "../../assets/images/info/background.png";

const StyledInfoItemBox = styled.div`
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr 1fr;
    width: 1100px;
    /* grid-gap: 28px; */
    /* justify-content: center; */
`;
const IMGContainer = styled.div`
    padding: 10px;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    .item-thumbnail {
        width: 100px;
    }
    .item-name {
        color: #fff;
        font-size: 24px;
        width: 100%;
        text-transform: capitalize;
        font-weight: 500;
        padding: 0 8px 0 16px;
        hr {
            margin-top: 22px;
            border-color: rgba(255, 255, 255, 0.2);
        }
    }
`;

const ItemBox = styled.div`
    position: relative;
    margin: 0 28px 28px 0;
    display: flex;
    img {
        width: 340px;
    }
`;

const InfoItemBox = ({ items, bannerType }) => {
    return (
        <StyledInfoItemBox>
            {items.map(({ item, src, element }, i) => (
                <ItemBox key={i}>
                    <img src={bg} alt="background" />
                    <IMGContainer>
                        <img
                            className="item-thumbnail"
                            src={
                                /**if banner is featured instead of weapon */
                                bannerType === "weapon"
                                    ? require(`../../assets/images/weapon/${src}.png`)
                                    : require(`../../assets/images/character/character-icons/${item.toLowerCase()}.png`)
                            }
                            alt="item-thumbnail"
                        />
                        <div className="item-name">
                            {item}
                            <hr />
                        </div>
                    </IMGContainer>
                </ItemBox>
            ))}
        </StyledInfoItemBox>
    );
};

export default InfoItemBox;
