import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { motion } from "framer-motion";

import {
    useGlobalDispatchContext,
    useGlobalStateContext,
} from "../context/globalContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faMusic,
    faVolumeDown,
    faVolumeMute,
    faVolumeUp,
} from "@fortawesome/free-solid-svg-icons";

const StyledVolumeButtons = styled.div`
    z-index: 9;
    /* position: relative; */
    display: flex;
    align-items: center;
    margin-left: 12px;
    button {
        outline: none;
        background-color: transparent;
        border: none;
        width: 32px;
        height: 32px;
        outline: none;
        z-index: 20;
        display: grid;
        place-items: center;
        .fas {
            color: #fff;
            font-size: 22px;
        }
        &.disabled {
            .fas {
                color: ${(props) => props.theme.warning};
            }
        }
    }
`;

const ModalBackdrop = styled.div`
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
`;

const VolumeButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 10px;
`;

const SliderContainer = styled(motion.div)`
    position: absolute;
    padding-left: 35px;
    margin-left: -5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 30px;
    background: ${(props) => props.theme.backgroundColor};
    border-radius: 50px;
    overflow: hidden;
    p {
        font-size: 14px;
        letter-spacing: -0.8px;
        color: #384054;
        margin-right: 10px;
    }
`;

const VolumeSlider = styled.input`
    &.slider {
        -webkit-appearance: none;
        height: 2px;
        background: #000;
        border-radius: 10px;
        outline: none;
        opacity: 0.7;
        margin: 0 10px;
        -webkit-transition: 0.2s;
        transition: opacity 0.2s;
    }

    &.slider:hover {
        opacity: 1;
    }
    &.slider::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 10px;
        height: 10px;
        border-radius: 1px;
        border: 1px solid #e4e2dc;
        box-shadow: 0 0 0 2px #5b6984;
        background: #5b6984;
        cursor: pointer;
        transform: rotate(45deg);
    }
    &.slider::-moz-range-thumb {
        width: 10px;
        height: 10px;
        border-radius: 1px;
        border: 1px solid #e4e2dc;
        box-shadow: 0 0 0 2px #5b6984;
        background: #5b6984;
        cursor: pointer;
        transform: rotate(45deg);
    }
`;

const VolumeButtons = () => {
    const [isOpen, setIsOpen] = useState(false);
    const globalDispatch = useGlobalDispatchContext();
    const { volume, playBGM } = useGlobalStateContext();

    const handleChange = (e) => {
        globalDispatch({
            type: "SET_VOLUME",
            payload: {
                masterVolume: e.target.value / 100,
                backgroundMusic: e.target.value / 100,
            },
        });
    };

    useEffect(() => {
        return () => {};
    }, []);

    return (
        <StyledVolumeButtons>
            {/* <button
                className={!playBGM ? "disabled" : ""}
                onClick={() =>
                    globalDispatch({
                        type: "SET_BACKGROUNDMUSIC",
                        payload: !playBGM,
                    })
                }
            >
                <FontAwesomeIcon icon={faMusic} className="fas fa-music" />
            </button> */}
            {isOpen && <ModalBackdrop onClick={() => setIsOpen(false)} />}
            <VolumeButtonContainer>
                <button onClick={() => setIsOpen(!isOpen)}>
                    <FontAwesomeIcon
                        icon={
                            volume.masterVolume > 0.01
                                ? faVolumeUp
                                : faVolumeMute
                        }
                        style={{ color: isOpen ? "#000" : "#fff" }}
                        className={
                            volume.masterVolume > 0.01
                                ? "fas fa-volume-up"
                                : "fas fa-volume-mute"
                        }
                    />
                </button>
                <SliderContainer
                    initial={{ width: "0%", opacity: 0 }}
                    animate={
                        isOpen
                            ? { width: "215px", opacity: 1 }
                            : { width: "0%", opacity: 0 }
                    }
                    transition={{ ease: "circOut" }}
                >
                    {isOpen && (
                        <>
                            <VolumeSlider
                                className="slider"
                                type="range"
                                min="0"
                                max="100"
                                value={volume.masterVolume * 100}
                                onChange={(e) => handleChange(e)}
                            />
                            <p>{Math.round(volume.masterVolume * 100)}</p>
                            {console.log(volume.masterVolume * 100)}
                        </>
                    )}
                </SliderContainer>
            </VolumeButtonContainer>
        </StyledVolumeButtons>
    );
};

export default VolumeButtons;
