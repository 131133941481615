import React from "react";
//Styled components
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";

import { device } from "../../util/device";

import { useGlobalStateContext } from "../../context/globalContext";

import { url } from "../../util/config.json";

const BannerFullIMG = styled(motion.img)`
    @media ${device.desktopL} {
        width: 1150px;
    }
    @media ${device.desktop} {
        width: 920px;
    }
    @media ${device.laptopL} {
        width: 800px;
    }
    @media ${device.tablet} {
        width: 100%;
    }

    margin-top: 24px;
`;

const StyledBanner = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Banner = () => {
    const { selectedBanner, currentBanners } = useGlobalStateContext();
    /*Make a check for if path fails, if img filename doesn't match**/

    return (
        <StyledBanner>
            <AnimatePresence exitBeforeEnter>
                {currentBanners.map(
                    ({ src }) =>
                        currentBanners[selectedBanner].src === src && (
                            <BannerFullIMG
                                preload="auto"
                                draggable="false"
                                style={
                                    src === "wanderlust-banner.png" && {
                                        marginTop: -25,
                                    }
                                }
                                key={src}
                                initial={{ opacity: 0, x: 30 }}
                                animate={{
                                    opacity: 1,
                                    x: 0,
                                    transition: {
                                        duration: 1.2,
                                        delay: 0.15,
                                        ease: [0.1, 0.8, 0.2, 1],
                                    },
                                }}
                                exit={{
                                    opacity: 0,
                                    transition: { duration: 0 },
                                }}
                                // src={`${url}/images/banners/${src}`}
                                src={`${url}/images/banners/${src}`}
                            />
                        )
                )}
            </AnimatePresence>
            {/* {console.log(`${url}/images/banners/${src}`)} */}
        </StyledBanner>
    );
};

export default Banner;
