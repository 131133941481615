import React, { useEffect, useCallback } from "react";
import styled from "styled-components";

import arrowIconRight from "../../assets/images/ui/arrow-button-right.png";
import arrowIconLeft from "../../assets/images/ui/arrow-button-left.png";

import bannerArrowSound from "../../assets/audio/banner-arrow-click.mp3";
// import backgroundMusic from "../../assets/audio/wish-background-music.mp3";
// import backgroundMusicPast from "../../assets/audio/wish-background-music-past.mp3";

import { Container } from "../../styles/globalStyles";
import WishHeader from "../../components/wishPage/WishHeader";
import Banner from "../../components/wishPage/Banner";
import WishFooter from "../../components/wishPage/WishFooter";

import {
    useGlobalStateContext,
    useGlobalDispatchContext,
} from "../../context/globalContext";

import { device } from "../../util/device";

const StyledWishContainer = styled(Container)`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    min-height: 100vh;
    @media ${device.maxSize} {
        padding: 15vh 15vw;
    }
`;

const ArrowButton = styled.img`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 28px;
    &.right-arrow {
        right: 45px;
    }
    &.left-arrow {
        left: 45px;
    }
    @media ${device.tablet} {
        display: none;
    }
`;

const Loader = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: inline-block;
    width: 140px;
    height: 140px;
    pointer-events: none;
    &:after {
        content: " ";
        display: block;
        width: 120px;
        height: 120px;
        margin: 8px;
        border-radius: 50%;
        border: 6px solid #fff;
        border-color: #fff transparent #fff transparent;
        animation: lds-dual-ring 1.2s linear infinite;
    }

    @keyframes lds-dual-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

const WishContainer = ({ playAudio, children }) => {
    const {
        bannerNumber,
        currentBanners,
        volume,
        playBGM,
        loading,
        isPastBanner,
        playClip,
    } = useGlobalStateContext();

    const globalDispatch = useGlobalDispatchContext();

    const arrowClick = (value) => {
        playAudio(bannerArrowSound);

        let newBannerNumber = bannerNumber;

        if (bannerNumber + value > currentBanners.length - 1) {
            newBannerNumber = 0;
        } else if (bannerNumber + value < 0) {
            newBannerNumber = currentBanners.length - 1;
        } else {
            newBannerNumber = newBannerNumber + value;
        }

        globalDispatch({
            type: "SET_BANNER",
            payload: newBannerNumber,
        });
    };

    const handleKeyPress = useCallback(
        ({ key }) => {
            //right arrow
            if (key === "ArrowRight") {
                arrowClick(1);
            }
            //left arrow
            if (key === "ArrowLeft") {
                arrowClick(-1);
            }
            if (key === "1") {
                playAudio(bannerArrowSound);
                globalDispatch({ type: "SET_BANNER", payload: 0 });
            }
            if (key === "2") {
                playAudio(bannerArrowSound);
                globalDispatch({ type: "SET_BANNER", payload: 1 });
            }
            if (key === "3") {
                playAudio(bannerArrowSound);
                globalDispatch({ type: "SET_BANNER", payload: 2 });
            }
            if (key === "4") {
                playAudio(bannerArrowSound);
                globalDispatch({ type: "SET_BANNER", payload: 3 });
            }
        },

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [globalDispatch, playAudio]
    );

    useEffect(() => {
        window.addEventListener("keydown", handleKeyPress);

        return () => {
            window.removeEventListener("keydown", handleKeyPress);
        };
    }, [handleKeyPress, volume, playBGM, playClip]);

    return (
        <StyledWishContainer>
            {loading && <Loader />}

            <ArrowButton
                draggable="false"
                onClick={() => arrowClick(1)}
                className="right-arrow"
                src={arrowIconRight}
            />
            <ArrowButton
                draggable="false"
                onClick={() => arrowClick(-1)}
                className="left-arrow"
                src={arrowIconLeft}
            />
            <WishHeader playAudio={playAudio} />
            {/**!Fix this issue where it double sends after selecting banner */}
            <Banner />
            <WishFooter playAudio={playAudio} />
            {children}
        </StyledWishContainer>
    );
};

export default WishContainer;
