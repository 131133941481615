import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";

const StyledInfoStarsHeader = styled.div`
    background-color: ${(props) =>
        props.rating === 5
            ? "#dcbba5"
            : props.rating === 4
            ? "#b6aabe"
            : "#a5bacd"};
    padding: 8px 14px;
    margin: 25px 0;
    display: grid;
    grid-template-columns: 185px 1fr;
    align-items: center;
    p {
        font-size: 20px;
        color: #4e4e4e;
    }
`;

const Rating = styled.div`
    .fa-star {
        font-size: 22px;
        margin: 0 3px;
        /* color: #f8cb34; */
        color: #ffcd2e;
    }
`;

const InfoStarsHeader = ({ children, rating, bgColor }) => {
    return (
        <StyledInfoStarsHeader rating={rating}>
            <Rating>
                {Array.apply(0, Array(rating)).map((star, i) => (
                    <FontAwesomeIcon
                        icon={faStar}
                        key={i}
                        className="fas fa-star"
                    />
                ))}
            </Rating>
            <p>{children}</p>
        </StyledInfoStarsHeader>
    );
};

export default InfoStarsHeader;
