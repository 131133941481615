import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { useDataStateContext } from "../../context/dataContext";
import { useGlobalStateContext } from "../../context/globalContext";

import dayjs from "dayjs";
import Select from "../Select";
import { device } from "../../util/device";

import { useTranslation } from "react-i18next";

const StyledHistory = styled.div`
    /* max-width: 1000px; */
    .description {
        margin-top: 30px;
        font-size: 26px;
    }
    .selector {
        p {
            font-size: 28px;
        }
        display: flex;
        align-items: center;
    }
    margin: 0 auto;
    @media ${device.maxSize} {
        width: 1300px;
    }
    @media ${device.desktopL} {
        width: 1300px;
    }
    @media ${device.desktop} {
        width: 1100px;
    }
    @media ${device.laptopL} {
        width: 1000px;
    }
    @media ${device.tablet} {
        width: 90%;
        .selector {
            p {
                font-size: 20px;
            }
        }
        .description {
            margin-top: 20px;
            font-size: 18px;
        }
    }
    @media ${device.mobileL} {
        width: 95%;
        .selector {
            p {
                font-size: 16px;
            }
        }
        .description {
            margin-top: 16px;
            font-size: 14px;
        }
    }
`;

const GridItems = styled.div`
    grid-template-columns: 55px 0.45fr 1fr 0.55fr;
    text-align: center;
    display: grid;
    border-left: 2px solid #d0cfcd;
    &.grid-header {
        border-top: 2px solid #d0cfcd;
    }
    &.empty {
        grid-template-columns: 1fr;
    }
`;

const GridItem = styled.div`
    border-bottom: 2px solid #d0cfcd;
    border-right: 2px solid #d0cfcd;
    padding: 20px;
    font-size: 18px;
    color: #666666;
    text-transform: capitalize;
    &.grid-head {
        background-color: #dbd7d3;
        font-weight: 600;
        color: #4d4d4d;
    }
    &.highlight-purple {
        color: #b459ff;
        font-weight: 500;
    }
    &.highlight-brown {
        color: #e97731;
        font-weight: 500;
    }
    @media ${device.tablet} {
        font-size: 12px;
        padding: 10px;
    }
    @media ${device.mobileL} {
        font-size: 10px;
        padding: 5px;
    }
`;

const ModalBackdrop = styled.div`
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
`;

const History = () => {
    const { t } = useTranslation();

    const wishCategories = {
        permanent: "Permanent Wish",
        beginner: "Novice Wishes",
        featured: "Character Event Wish",
        weapon: "Weapon Event Wish",
    };

    const { history, bannerStats } = useDataStateContext();
    const { selectedBanner, currentBanners } = useGlobalStateContext();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selected, setSelected] = useState("featured");

    const [filteredList, setFilteredList] = useState([]);

    const showFilter = {
        all: (item) => item,
        permanent: (item) => item.bannerType === "permanent",
        beginner: (item) => item.bannerType === "beginner",
        featured: (item) => item.bannerType === "featured",
        weapon: (item) => item.bannerType === "weapon",
    };

    const handleSelectChange = (option) => {
        setSelected(option);
        setFilteredList([...history].filter(showFilter[option]).reverse());
    };

    useEffect(() => {
        setSelected(currentBanners[selectedBanner].bannerType);

        setFilteredList(
            [...history]
                .filter(showFilter[currentBanners[selectedBanner].bannerType])
                .reverse()
        );
    }, [currentBanners[selectedBanner], history]);

    return (
        <StyledHistory>
            <ModalBackdrop onClick={() => setIsModalOpen(false)} />
            <div className="selector">
                <p>Select Wish Type:</p>
                <Select
                    handleSelectChange={handleSelectChange}
                    selected={selected}
                    setSelected={setSelected}
                    isOpen={isModalOpen}
                    setIsOpen={setIsModalOpen}
                    options={wishCategories}
                />
            </div>
            <p className="description">
                * You can check the wishes you made in the past month. Wish
                records are updated about one hour after the wish is made. If
                there is no record yet, please check again later. The times
                displayed below are according to your current server time.
                Please note any difference between the time zone of your server
                and the time zone of where you are located.
                <br />
                <br />
                <br />
                <span
                    style={{
                        color: "#e97731",
                        fontWeight: 600,
                    }}
                >
                    Current 5-Star Pity:{" "}
                </span>
                <span>{bannerStats[selected].pity5Star}</span>
            </p>
            <br />
            <br />
            <GridItems className="grid-header">
                <GridItem className="grid grid-head">#</GridItem>
                <GridItem className="grid grid-head">Item Type</GridItem>
                <GridItem className="grid grid-head">Item Name</GridItem>
                <GridItem className="grid grid-head">Time Received</GridItem>
            </GridItems>
            {filteredList.length ? (
                filteredList.map(
                    ({ type, name, rating, time, bannerType }, i) => (
                        <GridItems key={i}>
                            <GridItem
                                className={`grid ${
                                    rating === 4
                                        ? "highlight-purple"
                                        : rating === 5
                                        ? "highlight-brown"
                                        : ""
                                }`}
                                style={{ padding: "20px 0" }}
                            >
                                {filteredList.length - i}
                            </GridItem>
                            <GridItem>{t(type)}</GridItem>
                            <GridItem
                                className={
                                    rating === 4
                                        ? "highlight-purple"
                                        : rating === 5
                                        ? "highlight-brown"
                                        : ""
                                }
                            >
                                {t(name)} {rating > 3 && `(${rating}-Star)`}
                            </GridItem>
                            <GridItem>
                                {dayjs(time).format("YYYY-MM-DD HH:mm:ss")}
                            </GridItem>
                        </GridItems>
                    )
                )
            ) : (
                <GridItems className="empty">
                    <GridItem>No Record</GridItem>
                </GridItems>
            )}
        </StyledHistory>
    );
};

export default History;
