import React, { useState } from "react";
//Styled components
import styled from "styled-components";

import { motion } from "framer-motion";

import wishButton from "../../assets/images/ui/wish-button.png";
import FateIcon from "../FateIcon";

import wishClick from "../../assets/audio/wish-click.mp3";
import insufficientWishClick from "../../assets/audio/insufficient-wish-click.mp3";

import detailsClick from "../../assets/audio/details-click.mp3";

import { device } from "../../util/device";

import {
    useGlobalStateContext,
    useGlobalDispatchContext,
} from "../../context/globalContext";
import {
    useDataStateContext,
    useDataDispatchContext,
} from "../../context/dataContext";

import { roll } from "../../util/generateWish";
import { weaponRoll } from "../../util/generateWeaponWish";
import WishModal from "./WishModal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPowerOff } from "@fortawesome/free-solid-svg-icons";

import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";

const StyledWishFooter = styled(motion.nav)`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media ${device.maxSize} {
        .wish-button-img {
            height: 80px;
            width: 325px;
            margin-bottom: 8px;
        }
        .wish-button-text {
            top: 44%;
            font-size: 24px;
        }
        .info-button {
            font-size: 22px;
            height: 42px;
            width: 162px;
        }
        .reset-button {
            font-size: 26px;
            height: 40px;
            width: 40px;
            margin-right: 40px;
        }
    }
    @media ${device.desktopL} {
        .wish-button-img {
            height: 80px;
            width: 325px;
            margin-bottom: 8px;
        }
        .wish-button-text {
            top: 44%;
            font-size: 24px;
        }
        .info-button {
            font-size: 22px;
            height: 42px;
            width: 162px;
        }
        .reset-button {
            font-size: 26px;
            height: 40px;
            width: 40px;
            margin-right: 40px;
        }
    }
    @media ${device.desktop} {
        .wish-button-img {
            height: 65px;
            width: 258px;
        }
        .wish-button-text {
            font-size: 20px;
        }
        .fate-icon: {
            width: 20px;
        }
        .info-button {
            font-size: 19px;
            height: 34px;
            width: 126px;
        }
        .reset-button {
            font-size: 26px;
            height: 40px;
            width: 40px;
            margin-right: 30px;
        }
    }
    @media ${device.laptopL} {
        .wish-button-img {
            height: 55px;
            width: 218px;
        }
        .wish-button-text {
            font-size: 18px;
        }
        .fate-icon {
            width: 18px;
            margin-bottom: -3px;
        }
        .info-button {
            font-size: 16px;
            height: 28px;
            width: 104px;
        }
        .reset-button {
            font-size: 24px;
            height: 36px;
            width: 36px;
            margin-right: 14px;
        }
    }
    @media ${device.tablet} {
        .wish-button-text {
            font-size: 10px;
        }
        .fate-icon {
            width: 12px;
            margin-bottom: -3px;
            margin-right: 1px;
        }
        .wish-button-img {
            height: 40px;
            width: 158px;
        }
    }
    @media ${device.mobileL} {
        .wish-button-text {
            font-size: 8px;
        }
        .fate-icon {
            width: 9px;
            margin-bottom: -2px;
            margin-right: 1px;
        }
        .wish-button-img {
            height: 30px;
            width: 108px;
        }
    }
`;

const InfoContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 6px;

    @media ${device.tablet} {
        /* justify-content: flex-end; */
        display: grid;
        grid-template-columns: auto auto;
        grid-template-rows: auto auto;
        width: auto;
        margin: none;
        /* flex-wrap: wrap; */
    }
    @media ${device.mobileL} {
        margin-left: 40px;
    }
`;

const InfoButton = styled.button`
    z-index: 3;
    background-color: ${(props) => props.theme.backgroundColor};
    color: ${(props) =>
        props.warning ? props.theme.warning : props.theme.text};
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    box-shadow: ${(props) => props.theme.boxShadow};
    border-width: 0px;
    border-radius: 50px;
    letter-spacing: -1px;
    margin-left: 16px;
    font-size: 19px;
    height: 34px;
    width: 126px;
    @media ${device.tablet} {
        transform: scale(0.7);
        margin-left: 4px;
        &.reset-button {
            position: absolute;
            left: 20px;
        }
    }
    @media ${device.mobileL} {
        transform: scale(0.65);
        &.details {
            display: none;
        }
        &.reset-button {
            position: absolute;
            left: 15px;
            bottom: 15px;
        }
    }
`;

const WishContainer = styled.div`
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media ${device.tablet} {
        justify-content: flex-end;
        flex-wrap: wrap;
    }
`;
const WishButton = styled.div`
    z-index: 3;
    position: relative;
    margin-left: 16px;
    &.loading {
        p {
            color: lightgray;
        }
    }
`;

const WishButtonIMG = styled.img``;

const WishButtonText = styled.p`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: ${(props) => props.theme.textSecondary};
    img {
        margin: -10px -5px -6px 8px;
    }
    span {
        color: ${(props) => props.warning && props.theme.warning};
    }
`;

const WishFooter = ({ playAudio }) => {
    const [wishRequirement, setWishRequirement] = useState({
        fateRequired: 0,
        primogemCost: 0,
        fateCost: 0,
    });

    const { t } = useTranslation();

    const {
        selectedBanner,
        currentBanners,
        gachaData,
        wishAlert,
        loading,
        isUnlimited,
    } = useGlobalStateContext();
    const {
        fatesAmount,
        primogemsAmount,
        history,
        bannerStats,
    } = useDataStateContext();

    const dataDispatch = useDataDispatchContext();
    const globalDispatch = useGlobalDispatchContext();

    const {
        wishType,
        bannerId,
        bannerType,
        data,
        featured4Star,
        featured5Star,
    } = currentBanners[selectedBanner];

    const {
        pity4Star,
        pity5Star,
        guaranteedFeatured5Star,
        guaranteedFeatured4Star,
        beginnerRolled,
    } = bannerStats[bannerType];

    const computeFates = (fateCost) => {
        if (fateCost <= fatesAmount[wishType]) {
            dataDispatch({
                type: "SET_FATES",
                payload: {
                    ...fatesAmount,
                    [wishType]: fatesAmount[wishType] - fateCost,
                },
            });
            return true;
        } else if (primogemsAmount - fateCost * 160 >= 0) {
            dataDispatch({
                type: "SET_PRIMOGEMS",
                payload:
                    primogemsAmount - (fateCost - fatesAmount[wishType]) * 160,
            });
            dataDispatch({
                type: "SET_FATES",
                payload: {
                    ...fatesAmount,
                    [wishType]: 0,
                },
            });
            return true;
        } else {
            globalDispatch({
                type: "SET_WISHALERT",
                payload: "insufficient-primogems",
            });
            return false;
        }
    };

    const setPity = (results) => {
        const { rolledItems, rolledItemsRatings, beginnerRolled } = results;
        let newPityData;

        if (rolledItems.length === 1) {
            if (rolledItems[0].rating === 3) {
                newPityData = {
                    pity4Star: pity4Star + 1,
                    pity5Star: pity5Star + 1,
                };
            } else if (rolledItems[0].rating === 4) {
                newPityData = {
                    pity4Star: 0,
                    pity5Star: pity5Star + 1,
                };
            } else if (rolledItems[0].rating === 5) {
                newPityData = {
                    pity4Star: pity4Star + 1,
                    pity5Star: 0,
                };
            }
        } else if (rolledItems.length === 10) {
            newPityData = {
                pity4Star: 9 - rolledItemsRatings.lastIndexOf(4),
                pity5Star: rolledItemsRatings.includes(5)
                    ? 9 - rolledItemsRatings.lastIndexOf(5)
                    : pity5Star + 10,
            };
        }

        //checks for guaranteed featured item
        //if featured item exists in banner
        if (featured5Star) {
            dataDispatch({
                type: "SET_PITY",
                payload: {
                    ...bannerStats,
                    [bannerType]: {
                        ...newPityData,
                        guaranteedFeatured5Star:
                            results.guaranteedFeatured5Star,
                        guaranteedFeatured4Star:
                            results.guaranteedFeatured4Star,
                    },
                },
            });
        } else {
            //Checks if beginner banner
            if (bannerType !== "beginner") {
                dataDispatch({
                    type: "SET_PITY",
                    payload: {
                        ...bannerStats,
                        [bannerType]: {
                            ...newPityData,
                            guaranteedFeatured4Star: false,
                        },
                    },
                });
            } else {
                dataDispatch({
                    type: "SET_PITY",
                    payload: {
                        ...bannerStats,
                        [bannerType]: {
                            ...newPityData,
                            beginnerRolled,
                        },
                    },
                });
            }
        }
    };

    const handleWishClick = (fateCost) => {
        if (!loading) {
            if (isUnlimited) {
                setWishRequirement({
                    fateRequired: 0,
                    primogemCost: 0,
                    fateCost: 0,
                });
                playAudio(wishClick);
                makeWish(0);
            } else {
                setWishRequirement({
                    fateRequired: fateCost - fatesAmount[wishType],
                    primogemCost: `${(fateCost - fatesAmount[wishType]) * 160}`,
                    fateCost: fateCost,
                });
                if (fateCost <= fatesAmount[wishType]) {
                    playAudio(wishClick);
                    makeWish(fateCost);
                } else {
                    globalDispatch({
                        type: "SET_WISHALERT",
                        payload: "insufficient-fates",
                    });
                    playAudio(insufficientWishClick);
                }
            }
        }
    };

    const makeWish = (fateCost) => {
        globalDispatch({
            type: "SET_WISHALERT",
            payload: false,
        });
        let results;
        if (computeFates(fateCost)) {
            if (bannerType !== "weapon") {
                results = roll(
                    bannerId,
                    bannerType,
                    data,
                    featured4Star,
                    featured5Star,
                    pity4Star,
                    pity5Star,
                    guaranteedFeatured5Star,
                    guaranteedFeatured4Star,
                    gachaData,
                    beginnerRolled,
                    fateCost
                );
            } else {
                results = weaponRoll(
                    bannerId,
                    bannerType,
                    data,
                    featured4Star,
                    featured5Star,
                    pity4Star,
                    pity5Star,
                    guaranteedFeatured5Star,
                    guaranteedFeatured4Star,
                    gachaData,
                    beginnerRolled,
                    fateCost
                );
            }

            // globalDispatch({
            //     type: "LOADING",
            // });

            // setTimeout(() => {
            //     globalDispatch({
            //         type: "STOP_LOADING",
            //     });
            // }, 5000);

            globalDispatch({
                type: "SET_CLIP",
                payload: results.rollType,
            });
            dataDispatch({
                type: "SET_RESULTS",
                payload: { ...results },
            });

            setPity(results);
            dataDispatch({
                type: "SET_HISTORY",
                payload: history.concat(results.rolledItems),
            });
        }
    };

    const infoClick = (pageId) => {
        playAudio(detailsClick);
        globalDispatch({ type: "SET_DISPLAY", payload: pageId });
    };

    const handleReset = async () => {
        await dataDispatch({ type: "RESET_DATA" });
        await localStorage.clear();
        window.location.reload();
        return false;
    };

    return (
        <StyledWishFooter
            initial={{ y: 75, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5, ease: "circOut" }}
        >
            <InfoContainer>
                <InfoButton
                    className="reset-button"
                    onClick={() =>
                        globalDispatch({
                            type: "SET_WISHALERT",
                            payload: "reset-warning",
                        })
                    }
                    warning={true}
                >
                    <FontAwesomeIcon
                        icon={faPowerOff}
                        className="fas fa-power-off"
                    />
                </InfoButton>
                <InfoButton
                    className="info-button"
                    onClick={() => infoClick("shop")}
                >
                    {t("Shop")}
                </InfoButton>
                <InfoButton
                    className="info-button details"
                    onClick={() => infoClick("details")}
                >
                    {t("Details")}
                </InfoButton>
                <InfoButton
                    className="info-button"
                    onClick={() => infoClick("history")}
                >
                    {t("History")}
                </InfoButton>
                <InfoButton
                    className="info-button"
                    onClick={() => infoClick("collection")}
                >
                    {t("Collection")}
                </InfoButton>
            </InfoContainer>
            <WishContainer>
                {bannerType !== "beginner" && (
                    <WishButton
                        className={loading ? "loading" : ""}
                        onClick={() => handleWishClick(1)}
                    >
                        <WishButtonIMG
                            className="wish-button-img"
                            draggable="false"
                            src={wishButton}
                        />
                        <WishButtonText
                            className="wish-button-text"
                            warning={!isUnlimited && fatesAmount[wishType] < 1}
                        >
                            {t("Wish x1")}
                            <br />
                            <FateIcon wishType={wishType} /> <span>x 1</span>
                        </WishButtonText>
                    </WishButton>
                )}
                <WishButton
                    className={loading ? "loading" : ""}
                    onClick={() =>
                        handleWishClick(bannerType !== "beginner" ? 10 : 8)
                    }
                >
                    <WishButtonIMG
                        className="wish-button-img"
                        draggable="false"
                        src={wishButton}
                    />
                    <WishButtonText
                        className="wish-button-text"
                        warning={
                            !isUnlimited &&
                            fatesAmount[wishType] <
                                (bannerType !== "beginner" ? 10 : 8)
                        }
                    >
                        {t("Wish x10")}
                        <br />
                        <FateIcon wishType={wishType} />{" "}
                        <span>x {bannerType !== "beginner" ? 10 : 8}</span>
                    </WishButtonText>
                </WishButton>
            </WishContainer>
            {wishAlert === "insufficient-fates" && (
                <WishModal
                    title={t("Paimon's Bargains")}
                    onConfirm={() => makeWish(wishRequirement.fateCost)}
                    wishRequirement={wishRequirement}
                    wishType={wishType}
                    playAudio={playAudio}
                >
                    <p>
                        <Trans i18nKey="insufficientFates1">
                            An additional{" "}
                        </Trans>
                        <span style={{ color: "#F49C00" }}>
                            {wishRequirement.fateRequired}
                        </span>
                        {t(
                            wishType.charAt(0).toUpperCase() + wishType.slice(1)
                        )}
                        <Trans i18nKey="insufficientFates2">
                            {" "}
                            Fate are needed.
                            <br />
                            Purchase with{" "}
                        </Trans>
                        <span style={{ color: "#F49C00" }}>
                            {wishRequirement.primogemCost}
                        </span>
                        <Trans i18nKey="insufficientFates3"> Primogem?</Trans>
                    </p>
                </WishModal>
            )}
            {wishAlert === "insufficient-primogems" && (
                <WishModal
                    title={t("Primogem Top-Up")}
                    onConfirm={() =>
                        globalDispatch({ type: "SET_DISPLAY", payload: "shop" })
                    }
                    wishRequirement={wishRequirement}
                    playAudio={playAudio}
                >
                    <p>
                        <Trans i18nKey="insufficientPrimogems1">
                            Insufficient Primogems. Use{" "}
                        </Trans>
                        <span style={{ color: "#F49C00" }}>
                            {wishRequirement.primogemCost}
                        </span>
                        <Trans i18nKey="insufficientPrimogems2">
                            {" "}
                            Genesis Crystals to exchange for the required amount
                            of Primogems?
                        </Trans>
                    </p>
                </WishModal>
            )}
            {wishAlert === "reset-warning" && (
                <WishModal
                    title="Data Reset"
                    onConfirm={() => handleReset()}
                    playAudio={playAudio}
                >
                    <p>
                        Are you sure?
                        <br />
                        You are about to reset{" "}
                        <span style={{ color: "#F49C00" }}>ALL DATA</span>.
                        <br />
                        <br />
                        Hit <span style={{ color: "#F49C00" }}>CONFIRM</span> if
                        you wish to reset.
                    </p>
                </WishModal>
            )}
        </StyledWishFooter>
    );
};

export default WishFooter;
