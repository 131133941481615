import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";

import { device } from "../util/device";

const StyledSelect = styled.div`
    position: relative;
    color: #666666;
    width: 500px;
    margin-left: 26px;

    .selector {
        font-size: 24px;
        font-weight: 400;
        width: 100%;
    }
    @media ${device.tablet} {
        width: 50%;
        margin-left: 16px;
        .selector {
            font-size: 18px;
        }
    }
    @media ${device.mobileL} {
        width: 40%;
        margin-left: 8px;
        .selector {
            font-size: 12px;
        }
    }
`;

const MenuItems = styled.div`
    position: absolute;
    padding: 8px;
    width: 100%;
    margin-top: 26px;
    background-color: #dbd7d3;
    border: 2px solid #b5b2ad;
    border-radius: 5px;
`;

const MenuItem = styled.div`
    padding: 14px 18px;
    &.selected {
        background-color: #efece6;
    }
    &:hover {
        background-color: #efece6;
    }
    @media ${device.tablet} {
        padding: 8px 12px;
    }
    @media ${device.mobileL} {
        padding: 8px;
    }
`;

const InputLabel = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 22px;
    background-color: #dbd7d3;
    border-radius: 5px;
    border: 2px solid #b5b2ad;
    @media ${device.tablet} {
        padding: 8px 12px;
    }
    @media ${device.mobileL} {
        padding: 8px;
    }
    .fas {
        /* border-left: 2px solid #b5b2ad;
        padding-left: 18px; */
        font-size: 30px;
    }
    @media ${device.tablet} {
        .fas {
            font-size: 22px;
        }
    }
    @media ${device.mobileL} {
        .fas {
            font-size: 16px;
        }
    }
`;

const Select = ({
    options,
    selected,
    setSelected,
    isOpen,
    setIsOpen,
    handleSelectChange,
}) => {
    const handleSelect = (option) => {
        handleSelectChange(option);
        setIsOpen(false);
    };

    return (
        <StyledSelect>
            <InputLabel className="selector" onClick={() => setIsOpen(!isOpen)}>
                {options[selected]}
                {isOpen ? (
                    <FontAwesomeIcon
                        icon={faCaretUp}
                        className="fas fa-caret-up"
                    />
                ) : (
                    <FontAwesomeIcon
                        icon={faCaretDown}
                        className="fas fa-caret-down"
                    />
                )}
            </InputLabel>
            {isOpen && (
                <MenuItems>
                    {options &&
                        Object.keys(options).map((option, i) => (
                            <MenuItem
                                onClick={() => handleSelect(option)}
                                className={`selector ${
                                    selected === option ? "selected" : ""
                                }`}
                            >
                                {options[option]}
                            </MenuItem>
                        ))}
                </MenuItems>
            )}
        </StyledSelect>
    );
};

export default Select;
