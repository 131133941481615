import React from "react";
import styled from "styled-components";

const StyledPastWishHeader = styled.div`
    position: relative;
    width: 100%;
`;

const BannerIMG = styled.img`
    margin: 0 4px;
    &.banner-img-past {
        width: 80px;
    }
    &:hover {
        transform: scale(1.1);
    }
`;

const PastWishHeaderContainer = styled.div`
    /* left: 0;
    top: 0; */
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
`;

const PastWishHeader = ({ currentBanners, selectedBanner, bannerClick }) => {
    return (
        <StyledPastWishHeader>
            <PastWishHeaderContainer>
                {currentBanners.map((banner, i) => (
                    <BannerIMG
                        className="banner-img-past"
                        draggable="false"
                        key={banner.bannerId}
                        src={require(`../../assets/images/banners/${
                            banner.bannerId
                        }-${
                            currentBanners[selectedBanner].bannerId ===
                            banner.bannerId
                                ? "selected"
                                : "button"
                        }.png`)}
                        onClick={() => bannerClick(i)}
                    />
                ))}
            </PastWishHeaderContainer>
        </StyledPastWishHeader>
    );
};

export default PastWishHeader;
