import React, { useEffect, useState } from "react";
import styled from "styled-components";

import bg from "../../assets/images/ui/shop-background.jpg";

import CloseButton from "../CloseButton";
import PrimogemIcon from "../../assets/images/ui/primogem-icon.png";

import {
    useDataStateContext,
    useDataDispatchContext,
} from "../../context/dataContext";
import { device } from "../../util/device";

import {
    useGlobalStateContext,
    useGlobalDispatchContext,
} from "../../context/globalContext";

import genesisClick from "../../assets/audio/genesis-buy-click.mp3";
import shopClick from "../../assets/audio/shop-button-click.mp3";

import { motion } from "framer-motion";
import WishModal from "../wishPage/WishModal";

import { Trans, useTranslation } from "react-i18next";

import shopConfirmAudio from "../../assets/audio/shop-button-confirm.mp3";

const StyledShopPage = styled.div`
    @media ${device.maxSize} {
        .currency {
            font-size: 24px;
            padding: 4px 4px 4px 10px;
            .currency-img {
                margin-right: 14px;
                width: 24px;
            }
        }
        .add-button {
            margin-left: 8px;
            width: 28px;
            height: 28px;
            div {
                background-color: #000;
                width: 3px;
                height: 16px;
            }
        }
        .shop-button {
            width: 330px;
        }
        .shop-nav {
            width: 310px;
            .title {
                font-size: 34px;
                width: 100%;
                padding: 24px 32px;
                margin-bottom: 14px;
            }
            .shop-item {
                height: 64px;
                padding: 0 30px;
                font-size: 22px;
                margin-top: 16px;
            }
        }
    }
    @media ${device.desktopL} {
        .currency {
            font-size: 24px;
            padding: 4px 4px 4px 10px;
            .currency-img {
                margin-right: 14px;
                width: 24px;
            }
        }
        .add-button {
            margin-left: 8px;
            width: 28px;
            height: 28px;
            div {
                background-color: #000;
                width: 3px;
                height: 16px;
            }
        }
        .shop-button {
            width: 330px;
        }
        .shop-nav {
            width: 310px;
            .title {
                font-size: 34px;
                width: 100%;
                padding: 24px 32px;
                margin-bottom: 14px;
            }
            .shop-item {
                height: 64px;
                padding: 0 30px;
                font-size: 22px;
                margin-top: 16px;
            }
        }
    }
    @media ${device.desktop} {
        .currency {
            font-size: 17px;
            padding: 2px 3px 2px 9px;
            .currency-img {
                margin-right: 8px;
                width: 20px;
            }
        }
        .add-button {
            margin-left: 4px;
            width: 22px;
            height: 22px;
            div {
                background-color: #000;
                width: 3px;
                height: 16px;
            }
        }
        .shop-button {
            width: 250px;
        }
        .shop-nav {
            width: 246px;
            .title {
                font-size: 24px;
                width: 100%;
                padding: 24px 32px;
                margin-bottom: 8px;
            }
            .shop-item {
                height: 56px;
                padding: 0 30px;
                font-size: 18px;
                margin-top: 16px;
            }
        }
    }
    @media ${device.laptopL} {
        .currency {
            font-size: 17px;
            padding: 2px 3px 2px 9px;
            .currency-img {
                margin-right: 6px;
                width: 20px;
            }
        }
        .add-button {
            margin-left: 4px;
            width: 22px;
            height: 22px;
            div {
                background-color: #000;
                width: 3px;
                height: 16px;
            }
        }
        .shop-button {
            width: 150px;
        }
        .shop-nav {
            width: 246px;
            .title {
                font-size: 24px;
                width: 100%;
                padding: 24px 32px;
                margin-bottom: 8px;
            }
            .shop-item {
                height: 56px;
                padding: 0 30px;
                font-size: 18px;
                margin-top: 16px;
            }
        }
    }
    @media ${device.tablet} {
        .currency {
            font-size: 17px;
            padding: 2px 3px 2px 9px;
            .currency-img {
                margin-right: 8px;
                width: 20px;
            }
        }
        .add-button {
            margin-left: 4px;
            width: 22px;
            height: 22px;
            div {
                background-color: #000;
                width: 3px;
                height: 16px;
            }
        }
        .shop-button {
            width: 130px;
        }
        .shop-nav {
            width: 150px;
            .title {
                font-size: 18px;
                width: 100%;
                padding: 14px 32px;
                margin-bottom: 8px;
            }
            .shop-item {
                height: 30px;
                padding: 0 30px;
                font-size: 12px;
                margin-top: 16px;
            }
        }
    }
    @media ${device.tablet} {
        .shop-button {
            width: 100px;
        }
        .shop-nav {
            width: 100px;
            .title {
                font-size: 12px;
                width: 100%;
                padding: 14px 20px;
                margin-bottom: 8px;
            }
            .shop-item {
                height: 25px;
                padding: 0 10px;
                font-size: 8px;
                margin-top: 16px;
            }
        }
    }
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    z-index: 99;
    overflow: hidden;
    background: url(${bg}) center no-repeat;
    background-size: cover;
`;

const ShopBackground = styled(motion.div)`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    z-index: 99;
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.4),
        rgba(0, 0, 0, 0)
    );
    backdrop-filter: blur(4px);
    display: flex;
`;

const Title = styled(motion.div)`
    background-color: #3c4154;
    color: ${(props) => props.theme.textShop};
`;

const Nav = styled.ul`
    /* width: 310px; */
    height: 100%;
    /* background-color: #aaaaaa; */
    border: 0 solid #777777;
    border-width: 0 2px 0 2px;
    background: linear-gradient(
        to bottom,
        rgb(74, 82, 100) 300px,
        rgba(74, 82, 100, 0.2)
    );
    list-style-type: none;
    display: flex;
    flex-direction: column;
    /* grid-area: "nav"; */
`;

const LI = styled.li`
    display: flex;
    align-items: center;
    color: #ede6d3;
    &.shop-primogems {
        background-color: #ede4d9;
        color: #40465c;
        border: 2px solid #d0c1a6;
        box-shadow: 0 0 0 2px #ede4d9;
        width: 102%;
    }
`;

const GridItems = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 12px;
    width: 1000px;
    height: 550px;
    padding: 10px 110px;
    @media ${device.laptop} {
        grid-gap: 0;
        padding: 10px 50px;
        width: 800px;
    }
    @media ${device.tablet} {
        display: flex;
        flex-wrap: wrap;
        width: 80%;
        height: 60%;
        padding: 5px;
    }
    @media ${device.mobileL} {
        display: flex;
        flex-wrap: wrap;
        width: 80%;
        height: 60%;
        padding: 0px;
    }

    .dono-message {
        display: flex;
        align-items: center;
        flex-direction: column;
        color: #fff;
        padding: 20px;
        font-size: 14px;
        letter-spacing: -0.6px;
        /* background-color: rgba(255, 255, 255, 0.9); */
    }
    .coffee-button {
        margin-top: 10px;
        width: 120px;
        @media ${device.tablet} {
            width: 80px;
        }
        @media ${device.mobileL} {
            width: 60px;
        }
    }
`;

const GridItem = styled.div``;

const CurrencyContainer = styled.div`
    /* position: absolute; */
    /* right: 60px; */
    top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-self: flex-end;
    margin-right: 80px;
`;

const Currency = styled.div`
    /* min-width: 84px; */
    min-height: 30px;
    display: flex;
    align-items: center;
    border-width: 0px;
    border-radius: 50px;
    color: #fff;
    background-color: rgba(55, 65, 85, 0.5);
    letter-spacing: 0.6px;
    margin-right: 20px;
    .currency-value {
        margin-right: 10px;
    }
    .currency-spent {
        padding: 0 8px;
    }
`;

const AddButton = styled.button`
    position: relative;
    background-color: ${(props) => props.theme.backgroundColor};
    box-shadow: ${(props) => props.theme.boxShadow};

    border-width: 0;
    border-radius: 100%;
    display: grid;
    place-items: center;
    .line2 {
        position: absolute;
        transform-origin: center;
        transform: rotate(90deg);
    }

    margin-left: 10px;
    width: 24px;
    height: 24px;
    div {
        background-color: #000;
        width: 3px;
        height: 12px;
    }
`;

const ShopNotice = styled.p`
    font-size: 22px;
    letter-spacing: -1px;
    color: ${(props) => props.theme.textShop};
    @media ${device.tablet} {
        display: none;
    }
`;

const ShopHeaderContainer = styled(motion.div)`
    /* display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr 1fr; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 90px;
    width: 100%;
    padding: 0 40px;
`;

const ShopContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    z-index: 100;
`;

const ShopPage = ({ backButton, playAudio }) => {
    const { t } = useTranslation();

    const [cashRequirement, setCashRequirement] = useState({
        primogemsValue: 0,
        cashValue: 0,
        bonus: 0,
    });

    const { primogemsAmount, spent } = useDataStateContext();
    const { wishAlert } = useGlobalStateContext();
    const dataDispatch = useDataDispatchContext();
    const globalDispatch = useGlobalDispatchContext();
    const shopButton = (value, price, bonus) => {
        playAudio(genesisClick);
        globalDispatch({
            type: "SET_WISHALERT",
            payload: "purchase-primogems",
        });
        setCashRequirement({
            primogemsValue: value,
            cashValue: price,
            bonus: bonus,
        });
    };

    const purchasePrimogems = (value, price, bonus) => {
        let newVal = (parseFloat(spent) + price).toFixed(2);
        dataDispatch({
            type: "SET_PRIMOGEMS",
            payload: primogemsAmount + value + bonus,
        });
        dataDispatch({
            type: "SET_SPENT",
            payload: newVal,
        });
        globalDispatch({
            type: "SET_WISHALERT",
            payload: false,
        });
    };

    useEffect(() => {
        playAudio(shopClick);
    }, []);
    const { primogemsValue, bonus } = cashRequirement;
    return (
        <StyledShopPage>
            {/* <audio id="shop-open-sound" src={shopClick} autoPlay /> */}
            <ShopBackground
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                    duration: 0.6,
                    ease: "circOut",
                    delay: 0.3,
                }}
            >
                <Nav className="shop-nav">
                    <Title className="title">
                        <motion.p
                            initial={{ y: -50, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            transition={{
                                duration: 0.3,
                                ease: "circOut",
                                delay: 0.3,
                            }}
                        >
                            {t("shopTitle")}
                        </motion.p>
                    </Title>
                    <LI className="shop-item">{t("Recommended")}</LI>
                    <LI className="shop-item">{t("Gift Shop")}</LI>
                    <LI className="shop-item">{t("Paimon's Bargains")}</LI>
                    <LI className="shop-item shop-primogems">
                        {t("Crystal Top-Up")}
                    </LI>
                </Nav>
                <ShopContainer>
                    <ShopHeaderContainer
                        initial={{ y: -50, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{
                            duration: 0.3,
                            ease: "circOut",
                            delay: 0.3,
                        }}
                    >
                        <ShopNotice>
                            First-time buyer bonus NOT currently available.
                            <br />
                            <span style={{ fontSize: "14px" }}>
                                *Normal bonuses will be applied instead for now.
                            </span>
                        </ShopNotice>
                        <CurrencyContainer>
                            <Currency
                                className="currency"
                                // onClick={() => currencyClick()}
                            >
                                {/* <audio id="click4" src={click4} /> */}
                                <img
                                    className="currency-img"
                                    draggable="false"
                                    src={PrimogemIcon}
                                    alt="currency"
                                />
                                <p className="currency-value">
                                    {primogemsAmount}
                                </p>
                                <AddButton
                                    className="add-button"
                                    // onClick={(e) => addClick(e)}
                                >
                                    {/* <audio id="summonclick" src={summonclick} /> */}
                                    <div className="line1" />
                                    <div className="line2" />
                                </AddButton>
                            </Currency>
                            <Currency className="currency">
                                <p className="currency-value currency-spent">
                                    ${spent}
                                </p>
                            </Currency>
                        </CurrencyContainer>
                        <CloseButton onClick={() => backButton()} />
                    </ShopHeaderContainer>
                    <GridItems>
                        {[
                            { value: 60, price: 0.99, bonus: 0 },
                            { value: 300, price: 4.99, bonus: 30 },
                            { value: 980, price: 14.99, bonus: 110 },
                            { value: 1980, price: 29.99, bonus: 260 },
                            { value: 3280, price: 49.99, bonus: 600 },
                            { value: 6480, price: 99.99, bonus: 1600 },
                        ].map(({ value, price, bonus }, i) => (
                            <GridItem
                                key={i}
                                onClick={() => shopButton(value, price, bonus)}
                            >
                                <img
                                    draggable="false"
                                    className="shop-button"
                                    src={require(`../../assets/images/ui/${value}-genesis.png`)}
                                    alt="shop-item"
                                />
                            </GridItem>
                        ))}
                        <div className="dono-message">
                            <p>
                                If you like the app and want to support me to
                                keep improving this app, feel free to buy me
                                coffee!
                            </p>
                            <br />
                            <a
                                href="https://www.buymeacoffee.com/miwoju"
                                rel="noreferrer"
                                target="_blank"
                            >
                                <img
                                    className="coffee-button"
                                    src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png"
                                    alt="Buy Me A Coffee"
                                    // style={{
                                    //     width: "200px",
                                    // }}
                                />
                            </a>
                            <br />
                            Thank you and happy rolling!
                        </div>
                    </GridItems>
                </ShopContainer>
                {wishAlert === "purchase-primogems" && (
                    <WishModal
                        hr={false}
                        width="525px"
                        height="475px"
                        justifyContent={false}
                        title={t("Pay")}
                        playAudio={playAudio}
                        backgroundColor="#fff"
                        onConfirm={() =>
                            purchasePrimogems(
                                cashRequirement.primogemsValue,
                                cashRequirement.cashValue,
                                cashRequirement.bonus
                            )
                        }
                        customConfirmAudio={shopConfirmAudio}
                    >
                        <div
                            style={{
                                alignSelf: "flex-start",
                                marginTop: "30px",
                                fontSize: "18px",
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                            }}
                        >
                            <Trans>
                                <p>
                                    Product{" "}
                                    <span style={{ color: "#FF5B02" }}>
                                        {{ primogemsValue }}
                                    </span>{" "}
                                    Genesis
                                    <br /> +{" "}
                                    <span style={{ color: "#FF5B02" }}>
                                        {{ bonus }}
                                    </span>{" "}
                                    Bonus{" "}
                                </p>
                            </Trans>
                            {/* <p>
                                Product{" "}
                                <span style={{ color: "#FF5B02" }}>
                                    {cashRequirement.primogemsValue}
                                </span>{" "}
                                Genesis
                                <br /> +{" "}
                                <span style={{ color: "#FF5B02" }}>
                                    {cashRequirement.bonus}
                                </span>{" "}
                                Bonus
                            </p> */}
                            <p style={{ color: "#FF5B02", fontSize: "24px" }}>
                                $ {cashRequirement.cashValue}
                            </p>
                        </div>
                        <div
                            style={{
                                alignSelf: "flex-start",
                                marginTop: "30px",
                                fontSize: "18px",
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                            }}
                        >
                            <p>{t("Select payment type")}</p>
                            <p
                                style={{
                                    backgroundColor: "#FCF9F2",
                                    border: "1px solid #F3D8A8",
                                    padding: "6px",
                                    fontSize: "20px",
                                }}
                            >
                                {t("Imaginary Wallet")}
                            </p>
                        </div>
                    </WishModal>
                )}
            </ShopBackground>
        </StyledShopPage>
    );
};

export default ShopPage;
