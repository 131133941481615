import React, { useEffect, useState } from "react";
import styled from "styled-components";

import dayjs from "dayjs";

import { motion } from "framer-motion";
import primogemIcon from "../assets/images/ui/primogem-icon.png";

import { device } from "../util/device";

const StyledCountDown = styled(motion.div)`
    position: relative;
    background-color: #e4e2dc;
    /* border: 1px solid #dbcabf; */
    padding: 12px;
    border: 2px solid #dacbbf;
    color: #495365;
    max-width: 90%;
    /* box-shadow: 1px 1px 4px rgba(244, 156, 0, 0.4); */
    border-radius: 4px;
    .countdown-date {
        font-size: 20px;
    }
    .countdown-container {
        text-align: center;
        display: flex;
        /* grid-template-columns: 1fr 1fr 1fr 1fr; */
        align-items: center;
        justify-content: center;
        width: 100%;
        margin: 16px 0;
        .countdown {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 0 8px;
        }
        .number {
            font-size: 36px;
            color: #f49c00;
            text-shadow: 1px 1px rgba(0, 0, 0, 0.6);
        }
        .text {
        }
    }
    .message {
        font-size: 14px;
        color: #f49c00;
    }
    @media ${device.mobileL} {
        transform: scale(0.8);
    }
`;

const PrimogemIcon = styled(motion.img)`
    position: absolute;
    width: 30px;
    &.primogem-right {
        right: -40px;
    }
    &.primogem-left {
        left: -40px;
    }
    &.primogem-middle {
        right: -5px;
    }
`;

const CountDown = () => {
    const [countDown, setCountDown] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    });
    const date = dayjs("2021-03-06");
    let totalSeconds = date.diff(new Date(), "second");

    useEffect(() => {
        let countDownTimer = setTimeout(() => {
            setCountDown(returnElapsedTime(totalSeconds));
        }, 1000);

        return () => {
            clearTimeout(countDownTimer);
        };
    }, [countDown]);

    const returnElapsedTime = (epoch) => {
        //We are assuming that the epoch is in seconds
        let days = epoch / 86400;
        let hours = (days % 1) * 24;
        let minutes = (hours % 1) * 60;
        let seconds = (minutes % 1) * 60;
        // return `${Math.floor(days)} days, ${Math.floor(
        //     hours
        // )} hours, ${Math.floor(minutes)} minutes, ${Math.round(
        //     seconds
        // )} seconds`;

        return {
            days: Math.floor(days),
            hours: Math.floor(hours),
            minutes: Math.floor(minutes),
            seconds: Math.round(seconds),
        };
    };

    return (
        <StyledCountDown
            initial={{ y: 4, boxShadow: "0px 0px 0px 0px rgba(0, 0, 0, 0)" }}
            animate={{ y: 0, boxShadow: "1px 1px 3px 2px rgba(0, 0, 0, 0.4)" }}
            transition={{
                repeat: Infinity,
                repeatType: "reverse",
                duration: 1.6,
                ease: "circOut",
            }}
            whileHover={{ boxShadow: "0px 0px 0px 0px rgba(0, 0, 0, 0)" }}
            whileTap={{ boxShadow: "0px 0px 0px 0px rgba(0, 0, 0, 0)" }}
        >
            <PrimogemIcon
                className="primogem-right"
                drag
                layout
                dragTransition={{ bounceStiffness: 600, bounceDamping: 10 }}
                dragElastic={1}
                initial={{ left: "20%", top: "10%", rotate: -360, scale: 1.15 }}
                animate={{ left: "50%", top: "70%", rotate: 360, scale: 1 }}
                transition={{
                    repeat: Infinity,
                    repeatType: "mirror",
                    type: "spring",
                    stiffness: 60,
                    damping: 2,
                }}
                src={primogemIcon}
                whileHover={{ scale: 2 }}
                whileTap={{ scale: 2 }}
            />
            <PrimogemIcon
                className="primogem-left"
                drag
                layout
                dragTransition={{ bounceStiffness: 600, bounceDamping: 10 }}
                dragElastic={1}
                initial={{
                    left: "-40px",
                    top: "10%",
                    rotate: 0,
                    scale: 1.15,
                }}
                animate={{ left: "-20px", top: "70%", rotate: 180, scale: 1 }}
                transition={{
                    repeat: Infinity,
                    repeatType: "mirror",
                    type: "spring",
                    stiffness: 60,
                    damping: 4,
                }}
                src={primogemIcon}
                whileHover={{ scale: 2 }}
                whileTap={{ scale: 2 }}
            />
            <PrimogemIcon
                className="primogem-middle"
                drag
                layout
                dragTransition={{ bounceStiffness: 600, bounceDamping: 10 }}
                dragElastic={1}
                initial={{
                    right: "-20px",
                    top: "0%",
                    rotate: -720,
                    scale: 1.15,
                }}
                animate={{ right: "10px", top: "100%", rotate: 720, scale: 1 }}
                transition={{
                    repeat: Infinity,
                    repeatType: "mirror",
                    type: "spring",
                    stiffness: 30,
                    damping: 4,
                }}
                src={primogemIcon}
                whileHover={{ scale: 2 }}
                whileTap={{ scale: 2 }}
            />
            <div className="countdown-date">
                {dayjs("2021-03-06").format("YYYY-MM-DD")}
            </div>
            <div className="countdown-container">
                <div className="countdown">
                    <div className="number">{countDown.days}</div>
                    <div className="text">Days</div>
                </div>
                <div className="countdown">
                    <div className="number">{countDown.hours}</div>
                    <div className="text">Hours</div>
                </div>
                <div className="countdown">
                    <div className="number">{countDown.minutes}</div>
                    <div className="text">Minutes</div>
                </div>
                <div className="countdown">
                    <motion.div
                        // initial={{ opacity: 0, scale: 1.3, y: -20 }}
                        // animate={{ opacity: 1, scale: 1, y: 0 }}
                        // transition={{
                        //     repeat: Infinity,
                        //     duration: 1,
                        //     ease: "circOut",
                        // }}
                        className="number"
                    >
                        {countDown.seconds}
                    </motion.div>
                    <div className="text">Seconds</div>
                </div>
            </div>
            <div className="message">Coming soon!</div>
        </StyledCountDown>
    );
};

export default CountDown;
